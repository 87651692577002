import moment from 'moment';
import useTranslation from 'next-translate/useTranslation';
import { useEffect, useMemo, useState } from 'react';
import { WorkingHours } from 'klikni-jadi-shared-stuff';
import _, { lowerCase } from 'lodash';
import { Timestamp } from '@firebase/firestore-types';
import { days } from 'containers/details/DetailsContent';

export const useRestaurantOpenCloseLabels = (hours: WorkingHours, pausedUntil?: any) => {
    const { t } = useTranslation('common');
    const [isOpenNow, setIsOpenNow] = useState<boolean>(false);
    const [workingHours, setWorkingHours] = useState<any>(null);
    const [tomorrow, setTomorrow] = useState<number>(null);
    const [todayTos, setTodayTos] = useState<string[]>([]);
    const [currentTime, setCurrentTime] = useState<number>(null);
    const [openingDayLabel, setOpeningDayLabel] = useState<string>('');
    const [time, setTime] = useState<string>('');
    const [status, setStatus] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    let todayPeriods;

    const shorthandDaysMapper = useMemo(
        () => ({
            mon: { full: t('monday'), index: 1 },
            tue: { full: t('tuesday'), index: 2 },
            wed: { full: t('wednesday'), index: 3 },
            thu: { full: t('thursday'), index: 4 },
            fri: { full: t('friday'), index: 5 },
            sat: { full: t('saturday'), index: 6 },
            sun: { full: t('sunday'), index: 7 },
        }),
        [t]
    );

    useEffect(() => {
        setWorkingHours(
            weekdays
                .map(day => ({
                    day: shorthandDaysMapper[day].full,
                    periods: hours?.delivery[day] || [],
                    index: shorthandDaysMapper[day].index,
                }))
                .sort((a, b) => a.index - b.index)
        );
        setTomorrow(moment().isoWeekday());
        setCurrentTime(+moment().format('HHmm'));
    }, []);
    useEffect(() => {
        if (pausedUntil && isOpenNow) {
            setIsOpenNow(false);

            const timestampToString: string =
                new Date(pausedUntil._seconds * 1000).getHours().toLocaleString('en-US', {
                    minimumIntegerDigits: 2,
                    useGrouping: false,
                }) +
                new Date(pausedUntil._seconds * 1000).getMinutes().toLocaleString('en-US', {
                    minimumIntegerDigits: 2,
                    useGrouping: false,
                });

            if (parseInt(hours.delivery?.[days[new Date().getDay()].day]?.[0]?.to) < parseInt(timestampToString)) {
                setMessage(`${t('opens')} ${hours.delivery?.[days[new Date().getDay()].day]?.[0]?.to.replace(/(..)$/, ':$1')}`);
                setStatus(`${t('paused')}`);
            } else {
                setMessage(`${t('opens')} ${timestampToString.replace(/(..)$/, ':$1')}`);
                setStatus(`${t('paused')}`);
            }
        }
    }, [pausedUntil, isOpenNow]);

    useEffect(() => {
        if (workingHours) {
            todayPeriods = workingHours[tomorrow - 1].periods.map(period => ({
                from: +period.from,
                to: +period.to,
            }));
            setIsOpenNow(todayPeriods.find(t => currentTime < t.to && currentTime > t.from));
            const todayFroms = workingHours[tomorrow - 1].periods.map(p => +p.from).sort();
            setTodayTos(workingHours[tomorrow - 1].periods.map(p => +p.to).sort());
            const opensToday = todayFroms.find(t => t > currentTime);
            if (opensToday) {
                setMessage(`${t('opensToday')} ${opensToday.toString().replace(/(..)$/, ':$1')}`);
                setStatus(`${t('closed')}`);
            } else {
                let index = tomorrow % 7;
                for (let i = 0; i < 7; i++) {
                    const element = workingHours[index];
                    if (!_.isEmpty(element.periods)) {
                        const elementFroms = workingHours[index].periods.map(p => +p.from).sort();

                        const element =
                            elementFroms[0] === 0
                                ? '0000'
                                : elementFroms[0] < 100
                                ? `00${elementFroms[0]}`
                                : elementFroms[0] >= 100 && elementFroms[0] < 1000
                                ? `0${elementFroms[0]}`
                                : elementFroms[0];
                        const openTime = element.toString().replace(/(..)$/, ':$1');
                        const day = index === tomorrow ? t('tomorrow') : workingHours[index].day;

                        setMessage(`${t('opens')} ${day} ${openTime}`);
                        setStatus(`${t('closed')}`);
                        break;
                    }
                    index = (index + 1) % 7;
                }
            }
        }
    }, [workingHours, pausedUntil]);

    return {
        workingHours,
        isOpenNow,
        todayTos,
        status,
        messageClosed: `${t('opens')} ${
            openingDayLabel === 'today'
                ? lowerCase(t('today'))
                : openingDayLabel === 'tomorrow'
                ? lowerCase(t('tomorrow'))
                : openingDayLabel
        } ${time}`,
        messageOpen: `${t('closes')} ${lowerCase(t('today'))} ${todayTos
            .find(t => currentTime < +t)
            ?.toString()
            ?.replace(/(..)$/, ':$1')}`,
        message: isOpenNow
            ? `${t('closes')} ${lowerCase(t('today'))} ${todayTos
                  .find(t => currentTime < +t)
                  ?.toString()
                  ?.replace(/(..)$/, ':$1')}`
            : message,
    };
};

const weekdays = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
