import { Box, Tab, Tabs } from '@mui/material';
import { LargeText } from 'components/atoms/Typography';
import { useEffect, useState } from 'react';

type Filter = {
    title: string;
    id: string;
    hidden?: boolean;
    isOnPromotion?: boolean;
};

interface IHorizontalFilterNavigationProps {
    data: Filter[];
}

const HorizontalFilterNavigation = ({ data }: IHorizontalFilterNavigationProps) => {
    const [visibleId, setVisibleId] = useState<string>(data.length ? data[0].id : '');

    useEffect(() => {
        const callback: IntersectionObserverCallback = entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setVisibleId(entry.target.id);
                }
            });
        };
        const options = {
            root: null,
            rootMargin: '-30% 0px -40% 0px',
            threshold: 0,
        };

        const observer = new IntersectionObserver(callback, options);

        data.filter(x => !x.hidden).forEach(item => {
            const target = document.querySelector(`#${item.id}`);
            if (target) observer.observe(target);
        });
        return () => {
            observer.disconnect();
        };
        // const setVisibleElement = id => {
        //     const el = document.getElementById(id);
        //     if (el) {
        //         if (elementInViewport(el)) {
        //             setVisibleId(id);
        //         }
        //     }
        // };
        // const handleScroll = () => {
        //     data.forEach(item => {
        //         setVisibleElement(item.id);
        //     });
        // };
        // document.addEventListener('scroll', _.throttle(handleScroll, 1000));
        // return () => document.removeEventListener('scroll', handleScroll);
    }, []);

    const handleScrollToId = (e, id) => {
        setVisibleId(id);
        const elem = document.getElementById(id);

        if (elem) {
            window.scrollTo({
                top: elem.offsetTop - 220,
                left: window.scrollX,
                behavior: 'smooth',
            });
            // elem.scrollIntoView();
            // if (id !== 'details-location' && id !== 'details-review') window.scrollBy(0, -250);
            // else window.scrollBy(0, -80);
        }
        // alert(elem.clientHeight);

        // window.scrollTo(window.scrollX, window.scrollY - elem.clientHeight);
    };

    const tabs = data.filter(it => !it.hidden);

    return (
        <Box display="flex" width="101%" sx={{ backgroundColor: 'white', color: 'black' }} pb={1.5} pt={2} mb={2}>
            <Tabs
                value={visibleId}
                // indicatorColor=""
                textColor="inherit"
                onChange={handleScrollToId}
                sx={{
                    minWidth: 0,
                    minHeight: 0,
                    '& .MuiTabs-indicator': {
                        backgroundColor: 'black',
                    },
                }}
                variant="scrollable"
            >
                {tabs.map(item => {
                    if (item.id === 'category-promotions' || item.isOnPromotion) {
                        return (
                            <Tab
                                label={
                                    <LargeText
                                        sx={{
                                            textTransform: 'none',
                                            color: 'primary.light',
                                            ...(visibleId === item.id ? { fontWeight: 600 } : {}),
                                        }}
                                    >
                                        {item.title}
                                    </LargeText>
                                }
                                value={item.id}
                                key={item.id}
                                sx={{ minWidth: 0, minHeight: 0, px: 1, py: 1 }}
                            />
                        );
                    } else {
                        return (
                            <Tab
                                label={
                                    <LargeText
                                        sx={{
                                            textTransform: 'none',
                                            ...(visibleId === item.id ? { fontWeight: 600 } : {}),
                                        }}
                                    >
                                        {item.title}
                                    </LargeText>
                                }
                                value={item.id}
                                key={item.id}
                                sx={{ minWidth: 0, minHeight: 0, px: 1, py: 1 }}
                            />
                        );
                    }
                })}
            </Tabs>
        </Box>
    );
};

export default HorizontalFilterNavigation;
