import { Box, CircularProgress, Container, Typography } from '@mui/material';
import { useDetails } from 'hooks/useDetails';
import { stateHasFailed, stateIsLoaded } from 'services/stateHelpers';
import DetailsMenu from './components/DetailsMenu';
import DetailsReviews from './components/DetailsReviews';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';
import React, { useEffect, useMemo, useState } from 'react';
import { useFirestore } from 'hooks/useFirestore';
import { reviewsFirestoreActions } from 'redux/actions/firestore/reviews';
import { upperFirst } from 'lodash';
import useTranslation from 'next-translate/useTranslation';
import ModalPopup from 'components/molecules/ModalPopup';
import pauseModalImage from 'assets/svg/pauseModalImage.svg';
import { Headings } from 'components/atoms/Typography';
import { useRouter } from 'next/router';
import { useCurrentLocationContext } from 'context/location';

interface IDetailsContentProps {}

export const days = {
    1: { day: 'mon' },
    2: { day: 'tue' },
    3: { day: 'wed' },
    4: { day: 'thu' },
    5: { day: 'fri' },
    6: { day: 'sat' },
    7: { day: 'sun' },
};

const DetailsContent = ({}: IDetailsContentProps) => {
    const [pausedUntilString, setPausedUntilString] = useState<string>('');
    console.log('paused Until string: ', pausedUntilString);

    const restaurants = useDetails();
    const reviewsRef = useFirestore('reviews');
    const allReviews = useSelector((state: RootState) => state.reviews);
    const router = useRouter();
    const { data: location } = useCurrentLocationContext();

    useEffect(() => {
        if (restaurants.data) {
            reviewsRef.collection(reviewsFirestoreActions, {
                listen: false,
                queries: [
                    {
                        attribute: 'restaurantId',
                        operator: '==',
                        value: restaurants.data.id,
                    },
                ],
                sort: { attribute: 'createdAt', order: 'desc' },
            });
            if (restaurants?.data?.pausedUntil) {
                const timestampToString: string =
                    new Date(restaurants.data.pausedUntil.seconds * 1000).getHours().toLocaleString('en-US', {
                        minimumIntegerDigits: 2,
                        useGrouping: false,
                    }) +
                    new Date(restaurants.data.pausedUntil.seconds * 1000).getMinutes().toLocaleString('en-US', {
                        minimumIntegerDigits: 2,
                        useGrouping: false,
                    });

                if (
                    parseInt(restaurants?.data?.workingHours?.delivery?.[days[new Date().getDay()].day]?.[0]?.to) <
                    parseInt(timestampToString)
                ) {
                    setPausedUntilString(restaurants?.data?.workingHours?.delivery?.[days[new Date().getDay()].day]?.[0]?.to);
                } else {
                    setPausedUntilString(timestampToString);
                }
            }
        }
    }, []);

    const { t } = useTranslation('common');

    const navigationObj = useMemo(
        () => ({
            // 3: {
            //     title: t('about'),
            //     id: 'kjd-details-overview',
            // },
            1: {
                title: t('menu'),
                id: 'restaurant-menu',
            },
        }),
        [t]
    );

    if (stateHasFailed(restaurants)) {
        return (
            <Box className="container" display="flex" justifyContent="center">
                <Typography>{t('errorRetrievingRestaurant')}</Typography>
            </Box>
        );
    }

    if (!stateIsLoaded(restaurants)) {
        return (
            <Box className="container" display="flex" justifyContent="center">
                <CircularProgress />
            </Box>
        );
    }

    // if (restaurants.data?.gallery) {
    //     navigationObj[4] = {
    //         title: t('gallery'),
    //         id: 'kjd-details-gallery',
    //     };
    // }

    // if (restaurants.data?.attributes?.hasTableBooking) {
    //     navigationObj[2] = {
    //         title: t('bookatable'),
    //         id: 'kjd-details-bookatable',
    //     };
    // }

    if (allReviews.data && allReviews.data.length > 0) {
        navigationObj[4] = {
            title: t('review', { count: 10 }),
            id: 'restaurant-reviews',
        };
    }

    // const navigation = entries(navigationObj)
    //     .sort((x, y) => +x[0] - +y[0])
    //     .map(x => x[1]);

    return (
        <Box>
            <ModalPopup
                open={!restaurants?.data?.status?.isOpenForDelivery && restaurants?.data?.pausedUntil !== null}
                handleClose={() => {
                    router.push({
                        pathname: '/listings',
                        query: location?.coords?.latitude && {
                            nearby: `${[location.coords.latitude, location.coords.longitude].join(',')}`,
                        },
                    });
                }}
            >
                <Box textAlign="center" sx={{ padding: '50px' }}>
                    <Headings.H4>{t('restaurantIsPaused')}.</Headings.H4>
                    <img src={pauseModalImage} style={{ marginTop: '30px', marginBottom: '30px' }} />
                    <Headings.H4>
                        {upperFirst(t('restaurantOpensAt'))} {pausedUntilString.replace(/(..)$/, ':$1')}
                    </Headings.H4>
                </Box>
            </ModalPopup>

            <Box display="flex" flexDirection="column" justifyContent="center">
                {/* <Box zIndex={999} sx={{ display: { xs: 'none', md: 'block' } }}>
                    <Container>
                        <DetailsNavigation navigation={navigation} />
                    </Container>
                </Box> */}
                <Box mb={5} id="restaurant-menu">
                    <Container maxWidth="lg">
                        <DetailsMenu />
                    </Container>
                </Box>
                {/* {restaurants.data?.attributes?.hasTableBooking && (
                    <Box mb={5} id="kjd-details-bookatable">
                        <Container>
                            <GoogleMapsApiContainer>
                                <DetailsBookatable />
                            </GoogleMapsApiContainer>
                        </Container>
                    </Box>
                )} */}
                {/* <Box mt={4} display="flex" id="kjd-details-overview" zIndex={99} mb={5}>
                    <Box flex={2} pr={3}>
                    <Container>
                    <DetailsOverview />
                    </Container>
                    </Box>
                </Box> */}

                {/* {translate(restaurants?.data?.description) && (
                    <Box id="kjd-details-overview" mb={5}>
                        <Box textAlign="center">
                            <Headings.H4
                                sx={{
                                    color: 'primary.main',
                                    textDecoration: 'underline',
                                }}
                            >
                                {upperCase(t('about'))}
                            </Headings.H4>
                        </Box>
                        <Box mt={4} mb={5}>
                            <Container>
                                <NormalText>{ReactHtmlParser(translate(restaurants?.data?.description))}</NormalText>
                            </Container>
                        </Box>
                    </Box>
                )} */}

                {/* {restaurants.data?.gallery && !isEmpty(restaurants.data?.gallery) && (
                    <Box mb={5} id="kjd-details-gallery">
                        <Container>
                            <DetailsGallery />
                        </Container>
                    </Box>
                )} */}
                {/* <Box mt={5}>
                    <DetailsVouchers />
                </Box> */}

                {allReviews.data && allReviews.data.length > 0 && (
                    <Box pb={2} id="restaurant-reviews">
                        <Container maxWidth="lg">
                            <DetailsReviews />
                        </Container>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default DetailsContent;
