import { Box, Divider, useTheme } from '@mui/material';
import { Review } from 'klikni-jadi-shared-stuff';
import _ from 'lodash';
import moment from 'moment';
import useTranslation from 'next-translate/useTranslation';
import { useEffect, useMemo, useState } from 'react';
import { SmallText } from './atoms/Typography';

interface IDetailsReviewCardProps {
    review?: Review;
}
interface NewReview {
    title: string;
    type: string;
    value: number;
    index?: number;
}

const DetailsReviewCard = ({ review }: IDetailsReviewCardProps) => {
    const [newReview, setNewReview] = useState<NewReview[]>(null);
    const { t } = useTranslation('common');
    const theme = useTheme();

    const reviewMapper = useMemo(
        () => ({
            delicious: { title: t('delicious'), type: 'delicious', index: 0 },
            cheap: { title: t('cheap'), type: 'cheap', index: 1 },
            largePortions: { title: t('largePortions'), type: 'largePortions', index: 2 },
            orderAgain: { title: t('wouldOrderAgain'), type: 'orderAgain', index: 3 },
            authenticity: { title: t('authenticity'), type: 'authenticity', index: 4 },
            comment: { title: t('comment', { count: 1 }), type: 'comment', index: 5 },
        }),
        [t]
    );

    useEffect(() => {
        setNewReview(
            _.toPairs(review.restaurantReview)
                .map(rev => ({
                    type: reviewMapper[rev[0]].type,
                    title: reviewMapper[rev[0]].title,
                    value: rev[1],
                    index: reviewMapper[rev[0]].index,
                }))
                .sort((a, b) => a.index - b.index)
        );
    }, []);

    const renderReview = (review: NewReview, index: number) => {
        if (review.type !== undefined) {
            return (
                <Box pb={1} display="flex">
                    <Box px={2} display="flex">
                        <Box pr={1}>
                            {review.value === 0 ? (
                                <SmallText semibold color="error">
                                    x
                                </SmallText>
                            ) : (
                                <SmallText semibold color="primary">
                                    ✓
                                </SmallText>
                            )}
                        </Box>
                        <SmallText semibold color="textSecondary">
                            {' '}
                            {review.title}
                        </SmallText>
                    </Box>
                    {index < newReview.length - 1 && <Divider orientation="vertical" flexItem />}
                </Box>
            );
        }
    };
    return (
        <Box
            pb={1}
            display="flex"
            pt={2}
            sx={{ border: `1px solid ${theme.palette.grey[500]}`, borderRadius: '10px' }}
            px={1}
            mb={1}
            width="100%"
            minWidth={400}
        >
            <Box display="flex" flexDirection="column" justifyContent="space-between" width="100%">
                <Box display="flex" flexWrap={'wrap'}>
                    {newReview && newReview.map((rev, index) => renderReview(rev, index))}
                </Box>
                {review.restaurantReview?.comment !== '' && (
                    <Box pl={2} mt={1} mb={1}>
                        <SmallText>{review.restaurantReview?.comment}</SmallText>
                    </Box>
                )}
                <Divider />
                <Box pl={2} pt={1} display="flex">
                    <SmallText color="textSecondary">
                        {t('postedOn')} {moment(review.createdAt?.toDate()).format('DD.MM.YYYY')}
                    </SmallText>

                    <Box pl={1}>
                        {review.customer?.name === '' ? (
                            <SmallText color="textSecondary">{t('byAnonymous')}</SmallText>
                        ) : (
                            <SmallText color="textSecondary">
                                {t('by')} {review.customer?.name}
                            </SmallText>
                        )}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default DetailsReviewCard;
