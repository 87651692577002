import { Tab } from 'containers/details/components/DetailsMenuItemPaper';
import { CartItem, FeeType, FoodItem } from 'klikni-jadi-shared-stuff';
import { mapValues } from 'lodash';

export const generateItems = (tabs: Tab[], item: FoodItem, feeType: FeeType) => {
    let tabsStringified = tabs.map((t, index) => ({
        content: JSON.stringify(t),
        index,
    }));

    const _items: Partial<CartItem>[] = [];

    while (tabsStringified.length > 0) {
        const sample = tabsStringified[0];

        const _item: Partial<CartItem> = {
            quantity: 1,
            ingredients: item.ingredients,
            excluded: tabs[sample.index].ingredients.filter(ing => !ing.selected).map(ing => ing.ingredient),
            comment: tabs[sample.index].comment,
            id: item.id,
            portions: mapValues(item.portions, value => ({
                name: value.name,
                basePrice: value.basePrice,
                finalPrice: value.finalPrice?.[feeType] || 0,
                discountedPrice: value.discountedPrice?.[feeType] || 0,
            })),

            selectedPortionId: tabs[sample.index].selectedPortion,
            extras: tabs[sample.index].extras
                .filter(ex => ex.quantity)
                .map(ex => ({ id: ex.item.id, quantity: ex.quantity, categoryId: ex.item.categoryId })),
            categoryId: item.categoryId,
        };

        for (let i = 1; i < tabsStringified.length; i++) {
            const tab = tabsStringified[i];
            if (tab.content === sample.content) {
                _item.quantity++;
            }
        }
        _items.push(_item);
        tabsStringified = tabsStringified.filter(t => t.content !== sample.content);
    }

    return _items;
};
