import { Box } from '@mui/material';
import { RestaurantReviewAgg } from 'klikni-jadi-shared-stuff';
import _ from 'lodash';
import useTranslation from 'next-translate/useTranslation';
import { useEffect, useMemo, useState } from 'react';
import { elementInViewport } from 'services/utils';
import { NormalText, SmallText } from './atoms/Typography';
import { useTheme } from '@mui/material';

interface IReviewVoteProps {
    review?: RestaurantReviewAgg;
    hover?: boolean;
}
interface newReview {
    title: string;
    type: string;
    percent: number;
    total: number;
}

const ReviewVote = ({ review }: IReviewVoteProps) => {
    const [newReview, setNewReview] = useState<newReview[]>(null);
    const [visibleId, setVisibleId] = useState<string>('');
    const { t } = useTranslation('common');
    const theme = useTheme();

    const reviewMapper = useMemo(
        () => ({
            delicious: { title: t('delicious'), type: 'delicious', index: 0 },
            cheap: { title: t('cheap'), type: 'cheap', index: 1 },
            largePortions: { title: t('largePortions'), type: 'largePortions', index: 2 },
            orderAgain: { title: t('wouldOrderAgain'), type: 'orderAgain', index: 3 },
            authenticity: { title: t('authenticity'), type: 'authenticity', index: 4 },
            comment: { title: t('comment', { count: 1 }), type: 'comment', index: 5 },
        }),
        [t]
    );

    const toPercent = review => {
        if (review.yes && review.no) {
            const num = (review.yes / (review.yes + review.no)) * 100;
            return num;
        }

        if (review.yes && !review.no) return 100;
        if (!review.yes && review.no) return 0;
    };
    const getTotalVotes = num => {
        if (num.yes && num.no) {
            return num.yes + num.no;
        }
        if (num.yes && !num.no) {
            return num.yes;
        }
        if (!num.yes && num.no) {
            return num.no;
        }
    };
    useEffect(() => {
        setNewReview(
            _.toPairs(review)
                .map(rev => ({
                    type: reviewMapper[rev[0]].type,
                    title: reviewMapper[rev[0]].title,
                    percent: toPercent(rev[1]),
                    total: getTotalVotes(rev[1]),
                    index: reviewMapper[rev[0]].index,
                }))
                .sort((a, b) => a.index - b.index)
        );
    }, []);
    useEffect(() => {
        const setVisibleElement = id => {
            const el = document.getElementById(id);
            if (el) {
                if (elementInViewport(el)) {
                    setVisibleId(id);
                }
            }
        };
        const handleScroll = () => {
            setVisibleElement('reviews-vote');
        };
        document.addEventListener('scroll', _.throttle(handleScroll, 1000));
        return () => document.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <>
            {newReview &&
                newReview.map(
                    rev =>
                        rev.type !== 'comment' && (
                            <Box py={1} display="flex" flexDirection="column" id="reviews-vote" width="100%">
                                <Box display="flex" justifyContent="space-between">
                                    <Box pl="70px">
                                        <NormalText color="textSecondary">{rev.title}</NormalText>
                                    </Box>
                                    <SmallText color="textSecondary">
                                        {rev.total} {t('vote', { count: rev.total })}
                                    </SmallText>
                                </Box>
                                <Box display="flex" alignItems="center" width={{ md: 400 }} flex={{ xs: 1, md: undefined }}>
                                    <Box pr={1} display="flex" width="70px">
                                        <NormalText semibold color="primary">
                                            {Math.round(rev.percent)} %
                                        </NormalText>
                                    </Box>
                                    <Box
                                        sx={{
                                            borderRadius: '5px',
                                            border: `2px solid ${theme.palette.grey[100]}`,
                                            width: '100%',
                                            height: '30px',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                background: 'linear-gradient(269.81deg, #DAEDF3 -80.81%, #19A6B7 99.83%)',
                                                height: '100%',
                                                borderRadius: '5px',
                                                width: visibleId === 'reviews-vote' ? `${Math.round(rev.percent)}%` : '0px',
                                                transition: 'width 2s',
                                            }}
                                            style={
                                                visibleId === 'reviews-vote'
                                                    ? { width: `${Math.round(rev.percent)}%` }
                                                    : { width: '0px' }
                                            }
                                        ></Box>
                                    </Box>
                                </Box>
                            </Box>
                        )
                )}
        </>
    );
};

export default ReviewVote;
