import { Clear, SearchOutlined } from '@mui/icons-material';
import { Box, Grid, IconButton, OutlinedInput, Theme } from '@mui/material';
import { BoxProps, styled } from '@mui/system';
import { Headings } from 'components/atoms/Typography';
import MenuDish from 'components/molecules/MenuDish';
import { ModalPopup } from 'components/molecules/ModalPopup';
import FilterNavigationSidebar from 'components/organisms/FilterNavigationSidebar';
import HorizontalFilterNavigation from 'components/organisms/HorizontalFilterNavigation';
import CartItems from 'containers/cart/CartItems';
import { useHeadroom } from 'context/headroom';
import { useDataTranslator } from 'hooks/useDataTranslator';
import { useDetails } from 'hooks/useDetails';
import { useRestaurantOpenCloseLabels } from 'hooks/useRestaurantOpenCloseLabels';
import { FoodItem, LocaleStrings } from 'klikni-jadi-shared-stuff';
import _, { entries, filter, keys, size, upperFirst, values } from 'lodash';
import useTranslation from 'next-translate/useTranslation';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';
import ChooseDeliveryTimePopup from './ChooseDeliveryTimePopup';
import DetailsMenuItemPaper from './DetailsMenuItemPaper';
import { PreorderData } from 'containers/cart/WhenCart';
import { _generateToken } from 'services/cart/useCartService/token';
import moment from 'moment';
import { useRest } from 'hooks/useRest';
import { restExecutor } from 'services/rest-executor/executor';
import SellIcon from '@mui/icons-material/Sell';
import { checkServingTime } from '../helpers/checkServingTime';

interface IDetailsMenuProps {}

interface StickyBoxProps extends BoxProps {
    theme?: Theme;
}

const StickyTransitionBox = styled(Box)(({ theme }: StickyBoxProps) => ({
    transition: theme.transitions.create(['height', 'top'], {
        duration: theme.transitions.duration.standard,
        easing: theme.transitions.easing.easeInOut,
    }),
}));

const DetailsMenu = ({}: IDetailsMenuProps) => {
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const details = useDetails();
    const [filterKeyword, setFilterKeyword] = useState<string>('');
    const { headerPinned } = useHeadroom();
    const { translate } = useDataTranslator();
    const { t } = useTranslation('common');
    const [preorderPopupOpened, setPreorderPopupOpened] = useState<boolean>(false);
    const userCart = useSelector((state: RootState) => state.userCart);
    const { isOpenNow } = useRestaurantOpenCloseLabels(details.data?.workingHours);
    const cart = useSelector((state: RootState) => state.userCart);
    const [preorderData, setPreorderData] = useState<PreorderData>(null);
    const { propertiesAvailability } = useRest();
    const [promotionsData, setPromotionsData] = useState({
        wholeMenu: false,
        itemsOnPromotion: [],
        categoriesOnPromotion: [],
        validFor: [],
    });

    useEffect(() => {
        const restaurnatIds = new Set(
            cart?.data?.restaurantIds ? [details.data.id, ...cart.data.restaurantIds] : [details.data.id]
        ); // If user goes back to same restaurant that is already added to the cart, the resId will be duplicate, hence the use of set.
        _generateToken().then(_token => {
            propertiesAvailability
                .getOne(Array.from(restaurnatIds).join(','), null, { 'x-access-token': _token }, 'availableHours')
                .then(res => {
                    const _dates = values(res?.data?.results).map(item => moment(item.date));
                    const _allResults = values(res?.data?.results).reduce(
                        (acc, item) => ({
                            ...acc,
                            [moment(item.date).format('DD-MM-YYYY')]: item.times.map(ts => moment(ts, 'HHmm')),
                        }),
                        {}
                    );
                    setPreorderData({
                        days: _dates,
                        allResults: _allResults,
                        onlyPreorderAvailable: res?.data?.onlyPreorderAvailable,
                    });
                    console.log('LN', 'resd2', res?.data);
                });
        });
    }, [details?.data?.id]);

    // useEffect(() => {
    //     if (preorderData && shouldOpenPreorderPopup == null && cart.status === 3) {
    //         console.log('POPEN CART', cart);
    //         setOpenPreorderPopup();
    //     }
    // }, [preorderData, cart]);

    const shouldOpenPreorderPopup = () => {
        console.log('POPEN', cart);
        if (preorderData.onlyPreorderAvailable) {
            if (cart.data.isPreorder) {
                const date = moment(cart.data.deliveryDate.toDate());
                const checkDay = date.format('DD-MM-YYYY');
                const checkTime = date.format('HH:ss');
                if (
                    keys(preorderData?.allResults).includes(checkDay) &&
                    preorderData.allResults[checkDay].find(moment => moment.format('HH:ss') == checkTime)
                ) {
                    // setShouldOpenPreorderPopup(false);
                    console.log('POPEN', 'CHECKED');
                    return false;
                } else {
                    // setShouldOpenPreorderPopup(true);
                    console.log('POPEN', '!CHECKED', date);
                    return true;
                }
                // console.log('POPEN', 'date', date);
            } else {
                // setShouldOpenPreorderPopup(true);
                console.log('POPEN', 'NOT PREORDER');
                return true;
            }
        } else {
            // setShouldOpenPreorderPopup(false);
            console.log('POPEN', 'ALL OPEN');
            return false;
        }
    };

    const availableAttributes: String[] = entries(details?.data?.attributes)
        .filter(attribute => attribute[1] === true)
        .map(attribute => attribute[0]);

    const [openedItem, setOpenedItem] = useState<FoodItem>(null);

    const handleClose = () => {
        setModalOpen(false);
    };

    const handleAdd = () => {
        handleClose();
    };

    const handleOpenItem = item => {
        setOpenedItem(item);
        console.log('POPEN??');
        if (shouldOpenPreorderPopup()) {
            //&& !userCart.data.isPreorder
            setPreorderPopupOpened(true);
        } else {
            setModalOpen(true);
        }
    };

    const handlePreorderClose = () => {
        setPreorderPopupOpened(false);
        // setShouldOpenPreorderPopup(false);
    };

    const handlePreorderConfirm = () => {
        setPreorderPopupOpened(false);
        setModalOpen(true);
    };

    // console.log('POPEN details', cart);

    const filteredMenuItems = useMemo(() => {
        if (!details?.data?.foodMenu) return null;
        const _restaurantMenu = details.data.foodMenu;
        if (!_restaurantMenu || _restaurantMenu.length === 0) return null;
        const items = _restaurantMenu[0].items;
        if (!filterKeyword) return items;
        return filter(items, item => {
            if (item.name.en && item.name.mk)
                return (
                    item.name.en.toLowerCase().indexOf(filterKeyword.toLowerCase()) !== -1 ||
                    item.name.mk.toLowerCase().indexOf(filterKeyword.toLowerCase()) !== -1
                );
            if (!item.name.en) {
                if (item.name.mk) {
                    return item.name.mk.toLowerCase().indexOf(filterKeyword.toLowerCase()) !== -1;
                } else return false;
            }
            if (!item.name.mk) {
                if (item.name.en) {
                    item.name.en.toLowerCase().indexOf(filterKeyword.toLowerCase()) !== -1;
                } else return false;
            }
            return false;
        });
    }, [filterKeyword, details]);

    if (!details?.data?.foodMenu) return null;

    const restaurantMenu = details.data.foodMenu;

    if (!restaurantMenu || restaurantMenu.length === 0) return null;

    // * Fetch of promotions inside useEffect here
    useEffect(() => {
        const fetchPromotions = async () => {
            const promotionsExecutor = restExecutor(`/promotions/getDiscountedItemsAndCategories/${details.data.id}`, 'v1');
            const response = await promotionsExecutor.get();
            const promotions = response?.data;
            setPromotionsData(promotions);

            //     if (promotions.validFor.includes('delivery')) {
            //         setPromotionsData(promotions);
            //     }
        };

        fetchPromotions();
    }, []);

    const sidebarCategories = useMemo(() => {
        const promotionsCategory = {
            title: translate({
                en: 'Promotions',
                mk: 'Промоции',
            }),
            id: 'category-promotions',
            hidden: false,
            isOnPromotion: true,
        };

        const categories = _.entries(restaurantMenu[0].categories)
            .filter(([id, cat]) => !cat?.extra || (cat?.extra && cat?.show))
            .sort(
                (a, b) =>
                    (a[1].extra ? size(restaurantMenu[0].categories) + 1000 : a[1].sortNo) -
                    (b[1].extra ? size(restaurantMenu[0].categories) + 1000 : b[1].sortNo)
            )
            .map(([id, cat]) => ({
                title: translate(cat.name),
                id: `category-${id}`,
                hidden: filteredMenuItems.filter(it => it.categoryId === id).length === 0,
                isOnPromotion: !promotionsData.wholeMenu && promotionsData.categoriesOnPromotion.includes(id),
            }));

        if (promotionsData.itemsOnPromotion.length !== 0 && !promotionsData.wholeMenu) {
            categories.unshift(promotionsCategory);
        }

        return categories;
    }, [promotionsData]);

    return (
        <Box>
            <Box display="flex" mt={3} flexDirection={{ xs: 'column', md: 'row' }} width="100%">
                <Box sx={{ display: { xs: 'none', md: 'block' } }} width={250}>
                    <StickyTransitionBox position="sticky" top={headerPinned ? 120 : 30}>
                        <FilterNavigationSidebar data={sidebarCategories} />
                    </StickyTransitionBox>
                </Box>
                <Box
                    ml={{ xs: 0, md: '250px' }}
                    width={{ xs: '100%', md: 'calc(100% - 630px)' }}
                    display={{ xs: 'contents', md: 'none' }}
                >
                    <Box display="flex" width="100%" mb={0} maxHeight="40px" sx={{}} position="relative">
                        <OutlinedInput
                            margin="dense"
                            sx={{
                                borderRadius: 1,
                                flex: 1,
                                fieldset: {
                                    borderColor: 'transparent',
                                },
                                pl: '1.5rem',
                                border: '1px solid #e4e4e5',
                                backgroundColor: '#f6f6f6',
                                height: 40,
                            }}
                            color="primary"
                            placeholder={t('searchMenu')}
                            value={filterKeyword}
                            onChange={event => setFilterKeyword(event.currentTarget.value)}
                            size="small"
                        />
                        <SearchOutlined
                            sx={{
                                color: 'rgb(117, 117, 117)',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                left: 10,
                                display: 'inline-block',
                                position: 'absolute',
                                fontSize: '1.275rem',
                            }}
                        />
                    </Box>
                </Box>
                <Box
                    sx={{ display: { xs: 'block', md: 'none' } }}
                    position="sticky"
                    top={headerPinned ? 60 : 0}
                    zIndex={999}
                    mb={2}
                >
                    <HorizontalFilterNavigation data={sidebarCategories} />
                </Box>

                <Box display="flex" flex={1} width={{ xs: '100%', md: 'calc(100% - 250px)' }}>
                    <Box display="flex" flex={1} flexWrap="wrap" width="calc(100% - 380px)">
                        <Box
                            ml={{ xs: 0, md: '250px' }}
                            width={{ xs: '100%', md: 'calc(100% - 630px)' }}
                            display={{ xs: 'none', md: 'contents' }}
                        >
                            {promotionsData.wholeMenu && (
                                <Headings.H2
                                    fontWeight="bold"
                                    display="flex"
                                    width="100%"
                                    mb={4}
                                    maxHeight="40px"
                                    sx={{ color: 'primary.light' }}
                                    position="relative"
                                >
                                    {t('wholeMenuPromotion')}
                                </Headings.H2>
                            )}
                            <Box display="flex" width="100%" mb={4} maxHeight="40px" sx={{}} position="relative">
                                <OutlinedInput
                                    margin="dense"
                                    sx={{
                                        borderRadius: 1,
                                        flex: 1,
                                        fieldset: {
                                            borderColor: 'transparent',
                                        },
                                        pl: '1.5rem',
                                        border: '1px solid #e4e4e5',
                                        backgroundColor: '#f6f6f6',
                                        height: 40,
                                    }}
                                    color="primary"
                                    placeholder={t('searchMenu')}
                                    value={filterKeyword}
                                    onChange={event => setFilterKeyword(event.currentTarget.value)}
                                    size="small"
                                />
                                <SearchOutlined
                                    sx={{
                                        color: 'rgb(117, 117, 117)',
                                        top: '50%',
                                        transform: 'translateY(-50%)',
                                        left: 10,
                                        display: 'inline-block',
                                        position: 'absolute',
                                        fontSize: '1.275rem',
                                    }}
                                />
                            </Box>
                        </Box>

                        {promotionsData.itemsOnPromotion.length !== 0 && !promotionsData.wholeMenu && (
                            <CategorySection
                                category={{
                                    title: {
                                        en: 'promotions',
                                        mk: 'промоции',
                                    },
                                    id: 'promotions',
                                }}
                                items={filteredMenuItems.filter(foodItem =>
                                    promotionsData.itemsOnPromotion.includes(foodItem.id)
                                )}
                                promotionsSection
                                promotionService={promotionsData.validFor}
                                onClick={handleOpenItem}
                                key={'category-promotions'}
                            />
                        )}

                        {_.entries(restaurantMenu[0].categories)
                            .filter(([id, cat]) => !cat?.extra || (cat?.extra && cat?.show))
                            .sort(
                                (a, b) =>
                                    (a[1].extra ? size(restaurantMenu[0].categories) + 1000 : a[1].sortNo) -
                                    (b[1].extra ? size(restaurantMenu[0].categories) + 1000 : b[1].sortNo)
                            )
                            .map(([id, cat]) => ({
                                title: cat.name,
                                id: id,
                            }))
                            .map(item => (
                                <CategorySection
                                    category={item}
                                    items={filteredMenuItems.filter(it => it.categoryId === item.id)}
                                    onClick={handleOpenItem}
                                    promotionService={promotionsData.validFor}
                                    key={item.id}
                                    promotionsSection={
                                        promotionsData.categoriesOnPromotion.includes(item.id) || promotionsData.wholeMenu
                                    }
                                    promotionsItems={promotionsData.itemsOnPromotion}
                                />
                            ))}
                    </Box>
                    <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
                        <StickyTransitionBox
                            style={{
                                height: userCart.data.isEmpty
                                    ? 'auto'
                                    : headerPinned
                                    ? 'calc(100vh - 140px)'
                                    : 'calc(100vh - 50px)',
                            }}
                            ml={5}
                            position="sticky"
                            top={headerPinned ? 120 : 30}
                            alignSelf="flex-start"
                            display="flex"
                            flexDirection="column"
                        >
                            <Box maxHeight="100%" display="flex" flexDirection="column">
                                {(availableAttributes.includes('hasInhouse') ||
                                    availableAttributes.includes('hasDelivery') ||
                                    availableAttributes.includes('hasSelfPickup')) && <CartItems onlyPreorder={!isOpenNow} />}
                            </Box>
                            {/* <Box my={2} px={5}>
                                <Link href="/cart">
                                    <a>
                                        <PrimaryButton fullWidth>{upperCase(t('goToCart'))}</PrimaryButton>
                                    </a>
                                </Link>
                            </Box> */}
                        </StickyTransitionBox>
                    </Box>
                </Box>
            </Box>

            <ModalPopup
                open={modalOpen}
                handleClose={handleClose}
                maxWidth="100vw"
                maxHeight="100vh"
                minWidth="auto"
                minHeight="auto"
                boxStyle={{
                    borderRadius: '20px',
                }}
            >
                <>
                    <IconButton
                        sx={{
                            position: 'absolute',
                            right: 20,
                            top: 20,
                            zIndex: 10,
                            backgroundColor: '#e0e0e0',
                            color: '#555555',
                            p: 1,
                        }}
                        onClick={handleClose}
                        size="large"
                    >
                        <Clear />
                    </IconButton>
                    <DetailsMenuItemPaper onAdd={handleAdd} menuItem={openedItem} />
                </>
            </ModalPopup>
            <ChooseDeliveryTimePopup
                restaurantId={details.data.id}
                open={preorderPopupOpened}
                onClose={handlePreorderClose}
                onConfirm={handlePreorderConfirm}
                preorderData={preorderData}
            />
        </Box>
    );
};

interface ICategorySectionProps {
    category: { title: LocaleStrings; id: string };
    items: FoodItem[];
    promotionService: string[];
    promotionsSection?: boolean;
    onClick?: (item: FoodItem) => void;
    promotionsItems?: Array<string>;
}

const CategorySection = ({
    category,
    items,
    onClick,
    promotionService,
    promotionsSection,
    promotionsItems,
}: ICategorySectionProps) => {
    if (items.length === 0) return null;

    const { translate } = useDataTranslator();

    const handleClick = (item: FoodItem) => {
        if (onClick) onClick(item);
    };

    const hasAvailableItems = useMemo(() => {
        return items.some(item => checkServingTime(item));
    }, [items]);

    if (!hasAvailableItems) return null;

    // console.log('LNItems', items);

    return (
        <Box id={`category-${category.id}`} className="menu-items-category" width="100%" mb={7}>
            <Box mb={3}>
                {promotionsSection ? (
                    <Headings.H2
                        fontWeight="bold"
                        sx={{ color: 'primary.light', display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                    >
                        <div> {upperFirst(translate(category?.title))}</div>
                        <SellIcon sx={{ color: '#44B8FD', marginLeft: '0.5rem' }} />
                    </Headings.H2>
                ) : (
                    <Headings.H2 fontWeight="bold">{upperFirst(translate(category?.title))}</Headings.H2>
                )}
            </Box>
            <Grid container>
                {items
                    .filter(item => checkServingTime(item))
                    .map(it => (
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            md={12}
                            lg={12}
                            xl={12}
                            key={it.id}
                            sx={{
                                mx: -2,
                                px: 2,
                                maxWidth: 'calc(100% + 32px)!important',
                                flexBasis: '200%!important',
                                border: '1px solid',
                                borderWidth: '1.5px',
                                borderRadius: '0px',
                                // borderTopColor: '#e4e4e4',
                                borderBottomColor: 'transparent',
                                borderTopColor: 'transparent',
                                borderLeftColor: 'transparent',
                                borderRightColor: 'transparent',
                                transition:
                                    'border-color .3s ease-out,border-bottom-width 0ms .4s,border-radius 0ms .1s,border-bottom-left-radius 0ms .4s,border-bottom-right-radius 0ms .4s, border-radius .3s',
                                '&:hover': {
                                    borderColor: '#e4e4e4',
                                    borderRadius: '10px',
                                    borderWidth: '1.5px',
                                    '&+div': {
                                        borderColor: 'transparent',
                                        ' .paper-wrapper': {
                                            borderColor: 'transparent',
                                        },
                                    },
                                    ' .paper-wrapper': {
                                        borderColor: 'transparent',
                                    },
                                },
                                mb: '-3px',
                            }}
                        >
                            <MenuDish
                                onClick={() => handleClick(it)}
                                menuItem={it}
                                onPromotion={promotionsSection || promotionsItems.includes(it.id)}
                                promotionService={promotionService}
                            />
                            {/* <Divider /> */}
                        </Grid>
                    ))}
            </Grid>
        </Box>
    );
};

export default DetailsMenu;
