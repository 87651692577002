import { Box, Chip, PaperProps, Theme, Typography } from '@mui/material';
import { styled } from '@mui/system';
import Render from 'components/atoms/Render';
import ResponsiveImage from 'components/atoms/ResponsiveImage';
import { MediumText, NormalText } from 'components/atoms/Typography';
import { useDataTranslator } from 'hooks/useDataTranslator';
import { useDetails } from 'hooks/useDetails';
import { FoodItem } from 'klikni-jadi-shared-stuff';
import useTranslation from 'next-translate/useTranslation';
import { MouseEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';
import { checkUrl } from 'services/images/utils';
import { getMenuItemVisiblePrice } from 'services/utils';
import QrCodeIcon from '@mui/icons-material/QrCode';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';


interface IMenuDishProps {
    onClick?: (e: MouseEvent) => void;
    menuItem: FoodItem;
    onPromotion?: boolean;
    promotionService: string[];
}

interface PaperStyledProps extends PaperProps {
    theme?: Theme;
}

const PaperStyled = styled(Box)(({}: PaperStyledProps) => ({
    display: 'flex',
    flexDirection: 'column',
    // border: `0.2px ${theme.palette.grey[500]} solid`,
    // boxShadow: '0px 0px 3px 0px rgba(150,150,150,0.5)',
    cursor: 'pointer',
    transition: 'border-color .3s ease-out',
    // borderStyle: 'solid',
    // borderColor: '#e4e4e4',
    // borderWidth: '1px 0',
    '&:hover': {
        // border: `1px solid #efefef`,
        // borderColor: 'transparent',
    },
    // [theme.breakpoints.up('md')]: {
    //     // '&:hover': {
    //     //     transform: 'scale(1.05)',
    //     // },
    //     transition: theme.transitions.create('boxShadow', {
    //         easing: theme.transitions.easing.easeInOut,
    //         duration: theme.transitions.duration.short,
    //     }),
    // },
}));

const MenuDish = ({ onClick, menuItem, onPromotion, promotionService }: IMenuDishProps) => {
    const [validImage, setValidImage] = useState<boolean>(false);
    const { translate } = useDataTranslator();
    const details = useDetails();

    const cart = useSelector((state: RootState) => state.userCart.data);

    const { t } = useTranslation('common');



    const getIconFromPromotionType = () => {
        if (promotionService){
            if (promotionService.includes('delivery')) {
                return <DeliveryDiningIcon style={{ color: 'white', fontSize: 26 }} />;
            } else if (promotionService.includes('takeout')) {
                return <ShoppingBasketIcon style={{ color: 'white', fontSize: 24 }} />;
            } else if (promotionService.includes('inhouse')){
                return <QrCodeIcon style={{ color: 'white', fontSize: 26}} />;
            }
        }
    }

    useEffect(() => {
        checkUrl(menuItem.imageUrl?.replace('http://', 'https://'))
            .then(() => {
                setValidImage(true);
            })
            .catch(() => {
                setValidImage(false);
            });
    }, []);

    return (
        <Box
            sx={{
                position: 'relative',
                borderWidth: '1.5px',
                borderStyle: 'solid',
                borderBottomColor: 'transparent',
                borderTopColor: '#e4e4e4',
                borderLeftColor: 'transparent',
                borderRightColor: 'transparent',
                transition:
                    'border-color .3s ease-out,border-bottom-width 0ms .4s,border-radius 0ms .1s,border-bottom-left-radius 0ms .4s,border-bottom-right-radius 0ms .4s, border-radius .3s',
                mt: '-1px',
            }}
            className="paper-wrapper"
        >
            <PaperStyled onClick={onClick}>
                <Box display="flex" height={{ xs: 100, md: 116 }}>
                    <Box flex={1} overflow="hidden" height="100%">
                        <Box py={2} display="flex" flexDirection="column" height="100%" position="relative">
                            <Box sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                {onPromotion ? (
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <MediumText>
                                            {translate(menuItem.name) || menuItem.name.en || menuItem.name.mk}
                                        </MediumText>
                                    </Box>
                                ) : (
                                    <MediumText>{translate(menuItem.name) || menuItem.name.en || menuItem.name.mk}</MediumText>
                                )}
                            </Box>
                            <Render when={!!translate(menuItem.description)}>
                                <Box flex={1} minHeight={0} pr={2} display="flex" flexDirection="column">
                                    <Box
                                        sx={{
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            color: theme => theme.palette.grey[900],
                                        }}
                                    >
                                        <NormalText>{translate(menuItem.description)}</NormalText>
                                    </Box>
                                </Box>
                            </Render>
                            <Box
                                sx={{
                                    background:
                                        'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 58%, rgba(255,255,255,0.6909138655462185) 80%, rgba(255,255,255,0) 100%);',
                                    ...(!!translate(menuItem.description)
                                        ? {
                                              position: 'absolute',
                                              bottom: 10,
                                              width: '100%',
                                              pt: 1,
                                          }
                                        : {}),
                                }}
                            >
                                <MediumText sx={{ color: 'primary.main' }}>
                                    {onPromotion ? (
                                        <>
                                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                <Typography
                                                    sx={{
                                                        textDecoration: 'line-through',
                                                        marginRight: '1rem',
                                                        color: 'black',
                                                    }}
                                                >
                                                    {
                                                        getMenuItemVisiblePrice(
                                                            menuItem,
                                                            cart?.type,
                                                            details.data?.independentDelivery,
                                                            'online',
                                                            promotionService,
                                                        ).basePrice
                                                    }{' '}
                                                    {t('mkd')}.
                                                </Typography>
                                                <Typography sx={{ color: 'primary.main' }}>
                                                    {
                                                        getMenuItemVisiblePrice(
                                                            menuItem,
                                                            cart?.type,
                                                            details.data?.independentDelivery,
                                                            'online',
                                                            promotionService
                                                        ).discountedPrice
                                                    }{' '}
                                                    {t('mkd')}.
                                                </Typography>

                                                <Chip
                                                    label={`${menuItem.discount}% ${t('discount')}`}
                                                    icon={getIconFromPromotionType() || undefined} 
                                                    sx={{
                                                        marginLeft: '0.5rem',
                                                        backgroundColor: 'primary.light',
                                                        color: 'white',
                                                    }}
                                                />
                                            </Box>
                                        </>
                                    ) : (
                                        <>
                                            {
                                                getMenuItemVisiblePrice(
                                                    menuItem,
                                                    cart?.type,
                                                    details.data?.independentDelivery,
                                                    'online'
                                                ).finalPrice
                                            }{' '}
                                            {t('mkd')}.
                                        </>
                                    )}
                                </MediumText>
                            </Box>
                        </Box>
                    </Box>
                    <Box display="flex" p={1}>
                        {validImage && (
                            <Box>
                                <ResponsiveImage
                                    src={menuItem.imageUrl}
                                    sx={{
                                        height: { xs: 84, md: 100 },
                                        // objectFit: 'cover',
                                        borderRadius: '8px',
                                    }}
                                    options={{
                                        height: 100,
                                    }}
                                />
                            </Box>
                        )}
                    </Box>
                </Box>
            </PaperStyled>
        </Box>
    );
};

export default MenuDish;
