import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

const usePrevious = (value: DependencyList, initialValue: DependencyList) => {
    const ref = useRef(initialValue);
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};

export type EffectInspectorCallback = (
    changedDependencies: Record<string | number, { before: any; after: any }>
) => EffectCallback;

export const useEffectInspector = (
    callback: EffectInspectorCallback,
    dependencies: DependencyList,
    dependencyNames: string[] = []
) => {
    const previousDeps = usePrevious(dependencies, []);

    const changedDeps = dependencies.reduce((accum, dependency, index) => {
        if (dependency !== previousDeps[index]) {
            const keyName = dependencyNames[index] || index;
            return {
                ...accum,
                [keyName]: {
                    before: previousDeps[index],
                    after: dependency,
                },
            };
        }

        return accum;
    }, {});

    const changedDepsIndexes = dependencies.reduce((accum, dependency, index) => {
        if (dependency !== previousDeps[index]) {
            return {
                ...accum,
                index,
            };
        }

        return accum;
    }, []);

    useEffect(callback(changedDeps), dependencies);
};
