import { Box, Divider } from '@mui/material';
import { LargeText, NormalText } from 'components/atoms/Typography';
import DetailsReviewCard from 'components/DetailsReviewCard';
import ReviewVote from 'components/ReviewVote';
import { useDetails } from 'hooks/useDetails';
import useTranslation from 'next-translate/useTranslation';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';

interface IDetailsReviewsProps {}

const DetailsReviews = ({}: IDetailsReviewsProps) => {
    const restaurant = useDetails();
    const allReviews = useSelector((state: RootState) => state.reviews);
    const { t } = useTranslation('common');

    return (
        <div>
            <Box display="flex" flexDirection="column">
                <Box display="flex" flexDirection="column">
                    <LargeText semibold>{t('review', { count: 10 })}</LargeText>
                    <Box py={2}>
                        <NormalText>{t('overallRatings')}</NormalText>
                    </Box>
                </Box>

                <Box
                    display="flex"
                    py={2}
                    justifyContent="space-between"
                    alignItems="flex-start"
                    flexDirection={{ xs: 'column', md: 'row' }}
                >
                    <Box display="flex" flexDirection="column" width={{ xs: '100%', md: 'unset' }}>
                        <ReviewVote review={restaurant.data.reviews} />
                    </Box>

                    <Box
                        display="flex"
                        flexDirection="column"
                        maxHeight="400px"
                        overflow="auto"
                        minHeight="0"
                        pl={{ md: 4 }}
                        mt={{ xs: 3, md: 0 }}
                        width={{ xs: '100%', md: 'unset' }}
                        fontSize="14px"
                    >
                        {allReviews.data &&
                            allReviews.data
                                .filter(obj => obj.restaurantReview?.comment !== '')
                                .concat(allReviews.data.filter(obj => obj.restaurantReview?.comment === ''))
                                .map(review => (
                                    <Box>
                                        <DetailsReviewCard review={review} />
                                    </Box>
                                ))}
                    </Box>
                </Box>
            </Box>
        </div>
    );
};

export default DetailsReviews;
