import { Clear, ShoppingCart } from '@mui/icons-material';
import Add from '@mui/icons-material/Add';
import { Box, Button, Divider, IconButton, LinearProgress, TextField, Tooltip, Typography, useTheme } from '@mui/material';
import ingredientsIcon from 'assets/svg/ingredients-icon.svg';
import timeIcon from 'assets/svg/time-icon.svg';
import { PrimaryButton, ToggleButton } from 'components/atoms/Buttons';
import Render from 'components/atoms/Render';
import ResponsiveImage from 'components/atoms/ResponsiveImage';
import { PrimaryStatus } from 'components/atoms/Status';
import { LargeText, MediumText, NormalText, SmallText } from 'components/atoms/Typography';
import CartItemQuantityComponent from 'components/CartItemQuantityComponenet';
import ItemQuantityComponent from 'components/molecules/ItemQuantityComponent';
import ModalPopup from 'components/molecules/ModalPopup';
import { useAlertDialog } from 'hooks/useAlertDialog';
import { useDataTranslator } from 'hooks/useDataTranslator';
import { useDetails } from 'hooks/useDetails';
import { useFirestore } from 'hooks/useFirestore';
import useLocalStorage from 'hooks/useLocalStorage';
import { CartItem, FeeType, FoodItem, LocaleStrings, OrderType } from 'klikni-jadi-shared-stuff';
import _, { entries, findKey, forEach, isEmpty, map, min, sum, throttle, upperCase } from 'lodash';
import useTranslation from 'next-translate/useTranslation';
import React, { UIEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { restaurantsFirestoreActions } from 'redux/actions/firestore/restaurants';
import { RootState } from 'redux/reducers';
import { useCartService } from 'services/cart/useCartService';
import { generateItems } from 'services/cart/useCartService/itemsHandler';
import { getTopBottomColorsFromImage } from 'services/images/extractColors';
import { stateIsLoaded } from 'services/stateHelpers';

interface IDetailsMenuItemPaperProps {
    onAdd?: () => void;
    menuItem: FoodItem;
}

const DetailsMenuItemPaper = ({ onAdd, menuItem }: IDetailsMenuItemPaperProps) => {
    const [quantity, setQuantity] = useState<number>(1);
    const [allTabs, setAllTabs] = useState<Tab[]>([]);
    const [addingItems, setAddingItems] = useState<boolean>(false);
    const [scrollTop, setScrollTop] = useState<number>(0);

    const [validImage, setValidImage] = useState<boolean>(false);

    const [hasOptionForSameType, setHasOptionForSameType] = useState<boolean>(true);
    const [openAlertPopup, setOpenAlertPopup] = useState<boolean>(false);
    const [isDescriptionClicked, setIsDescriptionClicked] = useState<boolean>(false);

    const [topColor, setTopColor] = useState<string>('');
    const [bottomColor, setBottomColor] = useState<string>('');

    const [comment, setComment] = useState<string>('');

    const [menuWidth, setMenuWidth] = useState<number>(0);

    const details = useDetails();
    const { addItems, loading } = useCartService();
    const { alert } = useAlertDialog('ADD_ITEMS_ERROR');
    const [cartId, setCartId] = useLocalStorage('_cart_id');
    const cartService = useCartService();

    const cart = useSelector((state: RootState) => state.userCart);
    const currentUser = useSelector((state: RootState) => state.currentUser.data);

    const { t } = useTranslation('common');
    const { translate } = useDataTranslator();

    const { changeType } = useCartService();

    const restaurantFirestore = useFirestore('restaurants');
    const [imageChecked, setImageChecked] = useState<boolean>(false);

    const wannaAddItems = useRef<boolean>(false);

    const ref = useRef<HTMLDivElement>(null);

    const availableAttributes: String[] = entries(details?.data?.attributes)
        .filter(attribute => attribute[1] === true)
        .map(attribute => attribute[0]);

    useEffect(() => {
        if (stateIsLoaded(cart)) {
            if (wannaAddItems.current) {
                handleAddToCart();
            }
        }
    }, [cart]);

    useEffect(() => {
        let mounted = true;
        if (menuItem && mounted) {
            getTopBottomColorsFromImage(menuItem.imageUrl)
                .then(({ top, bottom }) => {
                    setTopColor(top);
                    setBottomColor(bottom);
                    setValidImage(true);
                    setImageChecked(true);
                })
                .catch(err => {
                    console.log(err);
                    if (err === 'Not valid url.') {
                        setValidImage(false);
                        setImageChecked(true);
                    }
                    setTopColor('rgba(255, 255, 255)');
                });
        }
        return () => {
            mounted = false;
        };
    }, []);

    useEffect(() => {
        const restaurantsIds = map(cart?.data?.groupedItems, item => item.restaurantId);

        if (restaurantsIds.length) {
            restaurantFirestore.collection(restaurantsFirestoreActions.all, {
                queries: [
                    {
                        attribute: 'id',
                        operator: 'in',
                        value: restaurantsIds,
                    },
                ],
            });
        }
    }, []);

    useEffect(() => {
        const el = document.getElementById('menu-wrapper');

        setMenuWidth(el?.clientWidth * 0.8);
    }, []);

    const feeType = useMemo(() => {
        const _type = cart.data.type;
        const _independentDelivery = details.data?.independentDelivery;

        let feeType: FeeType = 'delivery';

        if (_type === 'delivery' && !_independentDelivery) {
            feeType = 'delivery';
        } else if (_type === 'delivery' && _independentDelivery) {
            feeType = 'selfDelivery';
        } else {
            feeType = 'takeoutOnline';
        }

        return feeType;
    }, [cart.data.type, details.data?.independentDelivery]);

    const paperFinalPrice = useMemo(() => {
        if (!menuItem) return 0;

        return sum(
            map(
                allTabs,
                tab =>
                    (!isEmpty(menuItem.portions)
                        ? menuItem.portions[tab.selectedPortion].finalPrice?.[feeType]
                        : menuItem.finalPrice?.[feeType]) +
                    tab.extras.reduce((acc, extra) => acc + (extra?.item?.finalPrice?.[feeType] || 0) * (extra?.quantity || 0), 0)
            )
        );
    }, [menuItem, allTabs]);

    const foodItemExtras = useMemo(() => {
        const foodItems = details?.data?.foodMenu?.[0]?.items;
        if (!foodItems) {
            return [];
        }

        if (menuItem?.extrasIds?.length) {
            return foodItems
                .filter(foodItem => foodItem?.isExtra && menuItem.extrasIds.includes(foodItem?.id))
                .map(extra => ({ item: extra, selected: false, quantity: 0 }));
        }

        const categoryExtrasIds = details.data.foodMenu[0].categories?.[menuItem?.categoryId]?.extrasIds;
        if (categoryExtrasIds?.length) {
            return foodItems
                .filter(foodItem => foodItem?.isExtra && categoryExtrasIds.includes(foodItem?.id))
                .map(extra => ({ item: extra, selected: false, quantity: 0 }));
        }

        return [];
    }, [menuItem]);

    if (!menuItem) return null;

    const changeQuantity = (value: number) => setQuantity(value);

    const updateTabs = tabs => setAllTabs(tabs);

    const handleAddToCart = async () => {
        if (!cartId || cartId === 'undefined') {
            if (currentUser) {
                setCartId(currentUser.cartId);
            } else {
                const res = await cartService.createCart();
                cartService.setLoading();
                setCartId(res.data);
            }
            wannaAddItems.current = true;
        } else {
            setAddingItems(true);
            wannaAddItems.current = false;
            // const responses = [];
            let response;
            try {
                forEach(allTabs, tab => {
                    if (tab?.comment !== comment && comment !== '') {
                        tab.comment = comment;
                    }
                });

                if (
                    !!cart.data?.restaurantIds?.length &&
                    !cart.data.restaurantIds.includes(details.data.id) &&
                    !!cart.data?.coupon?.couponCode
                ) {
                    await cartService
                        .addCoupon({ couponCode: cart.data?.coupon?.couponCode, couponId: cart.data?.coupon?.couponId })
                        .then(() => {});
                }

                const _items = generateItems([...allTabs], menuItem, feeType);

                // for (const item of _items) {
                //     responses.push(
                //         await addItem(item, {
                //             id: details.data.id,
                //             activeFoodMenuId: details.data.activeFoodMenuId,
                //             partnerCode: details.data.partnerCode,
                //         })
                //     );
                // }
                response = await addItems(_items, {
                    id: details.data.id,
                    activeFoodMenuId: details.data.activeFoodMenuId,
                    partnerCode: details.data.partnerCode,
                });
            } catch (error) {
                console.log(t('errorAddingItemsToCart'), error);
            }

            // const unsuccessful = responses.find(r => !r.success);

            if (!response?.success) {
                setAddingItems(false);
                alert(
                    {
                        title: response?.message,
                        description: response?.data.message,
                        successText: 'OK',
                        successColor: 'primary',
                        cancelColor: 'secondary',
                        cancelable: false,
                    },
                    () => {}
                );
            } else {
                if (onAdd) {
                    onAdd();
                    setAddingItems(false);
                }
            }
        }
    };

    const compareRestaurantsStatus = () => {
        if (cart.data.type === 'delivery') {
            if (!details.data.attributes.hasDelivery) {
                forEach(cart.data.groupedItems, restaurant => {
                    if (!restaurant?.restaurantAttributes?.hasSelfPickup) {
                        setHasOptionForSameType(false);
                    }
                });
                setOpenAlertPopup(true);
            } else {
                handleAddToCart();
            }
        } else if (cart.data.type === 'takeout') {
            if (!details.data.attributes.hasSelfPickup) {
                forEach(cart.data.groupedItems, restaurant => {
                    if (!restaurant?.restaurantAttributes?.hasDelivery) {
                        setHasOptionForSameType(false);
                    }
                });
                setOpenAlertPopup(true);
            } else {
                handleAddToCart();
            }
        } else if (!cart.data.type) {
            handleAddToCart();
        }
    };

    const handleCloseAlertPopup = () => {
        setOpenAlertPopup(false);
        onAdd();
    };

    const handleChangeCartDeliveryType = () => {
        if (hasOptionForSameType) {
            if (cart.data.type === 'delivery') {
                changeType('takeout');
            } else {
                changeType('delivery');
            }

            handleAddToCart();
            handleCloseAlertPopup();
        }
    };

    const handleDescriptionClicked = () => {
        setIsDescriptionClicked(true);
    };

    const handleChangeComment = e => {
        setComment(e.target.value);
    };

    const handleScroll = (e: UIEvent) => {
        e.stopPropagation();
        setScrollTop(e.currentTarget.scrollTop);
    };

    return (
        <Box
            display="flex"
            position="relative"
            flexDirection={{ xs: 'column', md: 'column' }}
            // height={{ xs: 'calc(var(--vh, 1vh) * 100)', md: '100%' }}
            // maxHeight="webkit-fill-available"
            maxHeight="calc(var(--vh, 1vh) * 90)"
            flex={1}
            // width={{ xs: '90vw', md: 'unset' }}
            width={500}
            maxWidth="100vw"
            justifyContent="space-between"
            style={{
                padding:
                    'env(safe-area-inset-top), env(safe-area-inset-right), env(safe-area-inset-bottom), env(safe-area-inset-left)',
            }}
            id="menu-wrapper"
            // minWidth={{ xs: 'unset', md: 600 }}
        >
            {/* {!topColor && (
                <Box width="100%" display="flex" justifyContent="center" alignItems="center">
                    <Box width="50%">
                        <LinearProgress color="primary" />
                    </Box>
                </Box>
            )} */}
            {/* {topColor && ( */}
            <>
                <Box flex={1} minHeight={0} display="flex" flexDirection="column">
                    <Box
                        flex={1}
                        overflow="auto"
                        minHeight={0}
                        display="flex"
                        flexDirection={{ xs: 'column', md: 'column' }}
                        onScroll={throttle(handleScroll, 5)}
                    >
                        {/* {!imageChecked && (
                            <Box
                                sx={{
                                    height: { xs: 675 / (1200 / (menuWidth || 1)), md: 300 },
                                    width: '100%',
                                }}
                            />
                        )} */}
                        {/* {validImage && ( */}
                        <>
                            <Box
                                sx={{
                                    // overflow: 'hidden',
                                    width: '100%',
                                    // paddingTop: { xs: `${menuWidth - 50}px`, md: '380px' },
                                    position: 'relative',
                                }}
                            >
                                {menuItem.imageUrl && (
                                    <Box
                                        sx={{
                                            // position: 'absolute',
                                            left: '0px',
                                            right: '0px',
                                            bottom: '0px',
                                            // transformOrigin: 'center bottom',
                                            opacity: '1',
                                            // height: {
                                            // xs: scrollTop < menuWidth ? menuWidth - scrollTop : 0,
                                            // md: scrollTop < 430 ? 430 - scrollTop : 0,
                                            // },
                                        }}
                                    >
                                        <ResponsiveImage
                                            src={menuItem.imageUrl}
                                            sx={{
                                                // height: '100%',
                                                width: '100%',
                                                // objectFit: 'cover',
                                                // objectPosition: '50% 50%',
                                            }}
                                            options={{
                                                height: 900,
                                            }}
                                        />
                                    </Box>
                                )}
                            </Box>
                            {/* <Box
                                    style={{
                                        background: `linear-gradient(${topColor}, ${bottomColor})`,
                                    }}
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="center"
                                    justifyContent="center"
                                    // width={{ xs: 'unset', sm: 'unset', md: 'unset' }}
                                    // height={{ xs: 200, sm: 400, md: 'unset' }}
                                >
                                    <Box
                                        // maxHeight={{ xs: '100%', md: '80%' }}
                                        // maxWidth={{ xs: '100%', md: '100%' }}
                                        display="flex"
                                        flexDirection="column"
                                        alignItems="center"
                                        justifyContent="center"
                                        // width={{ xs: 'unset', md: '100%' }}
                                        width="100%"
                                        borderRadius="undefinedpx"
                                        overflow="hidden"
                                        height={300}
                                    >
                                        <img
                                            src={menuItem.imageUrl}
                                            style={{
                                                // width: '100%',
                                                // maxWidth: '400px',
                                                // objectFit: 'cover',
                                                // maxHeight: '100%',
                                                width: '100%',
                                                borderRadius: 20,
                                            }}
                                        />
                                    </Box>
                                </Box> */}
                            {/* <Divider flexItem orientation="vertical" /> */}
                        </>
                        {/* )} */}
                        <Box
                            flex={1}
                            display="flex"
                            flexDirection="column"
                            justifyContent="space-between"
                            minWidth={{ xs: 'unset', md: 400 }}
                        >
                            <Box p={3}>
                                <Box width={'100%'} maxWidth={{ xs: 'unset', md: 600 }}>
                                    <Box pr={6} display="flex" alignItems="center">
                                        <Box flex={1}>
                                            <Typography variant="h5" sx={{ paddingRight: '2px', mr: 2 }}>
                                                <b>{translate(menuItem.name)}</b>
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <PrimaryStatus>No. #{menuItem.itemNo}</PrimaryStatus>
                                        </Box>
                                    </Box>
                                    <Box>
                                        <Typography
                                            sx={{
                                                WebkitLineClamp: 2,
                                                display: '-webkit-box',
                                                WebkitBoxOrient: !isDescriptionClicked ? 'vertical' : 'unset',
                                                overflow: 'hidden',
                                            }}
                                            onClick={handleDescriptionClicked}
                                        >
                                            {translate(menuItem.description)}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box width="100%">
                                    <IngredientsView
                                        ingredients={
                                            menuItem.ingredients?.map(ing => ({
                                                ingredient: ing,
                                                selected: true,
                                            })) || []
                                        }
                                        extras={foodItemExtras}
                                        quantity={quantity}
                                        updateTabs={updateTabs}
                                        menuItem={menuItem}
                                        onNewTab={() => changeQuantity(quantity + 1)}
                                        onCloseTab={() => changeQuantity(quantity - 1)}
                                        cartType={cart?.data?.type}
                                        feeType={feeType}
                                    />
                                </Box>
                            </Box>

                            <Box sx={{ display: { xs: 'block', sm: 'block' } }}>
                                <Box>
                                    {!menuItem?.ingredients?.length && !menuItem?.portions && !menuItem.extrasIds?.length && (
                                        <Box p={3} width="100%" py={1} display="flex" flexDirection="column">
                                            <Box
                                                width="100%"
                                                display="flex"
                                                alignItems="baseline"
                                                justifyContent="space-between"
                                                fontSize="0.875rem"
                                            >
                                                <TextField
                                                    sx={{
                                                        '& .MuiOutlinedInput-input': { height: '10px', fontSize: '0.875rem' },
                                                        marginTop: '5px',
                                                        marginBottom: '25px',
                                                    }}
                                                    fullWidth
                                                    value={comment}
                                                    placeholder={t('addComment')}
                                                    onChange={handleChangeComment}
                                                />
                                            </Box>
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box>
                        <Divider />
                        <Box py={2} px={3} display="flex" justifyContent="space-between" alignItems="center" minWidth={300}>
                            {/* <ItemQuantityComponent onChange={changeQuantity} value={quantity} /> */}
                            <Box sx={{}}>
                                <CartItemQuantityComponent
                                    onChange={changeQuantity}
                                    value={quantity}
                                    canRemove={false}
                                    openAllTime
                                    containerWidth={120}
                                />
                            </Box>

                            {(availableAttributes.includes('hasInhouse') ||
                                availableAttributes.includes('hasDelivery') ||
                                availableAttributes.includes('hasSelfPickup')) && (
                                <Box pl={2} flex={1}>
                                    <PrimaryButton
                                        onClick={compareRestaurantsStatus}
                                        style={{ minWidth: '100px' }}
                                        loading={loading}
                                        fullWidth
                                    >
                                        <Box display="flex">
                                            {/* {upperCase(t('addToCart'))} */}
                                            <ShoppingCart
                                                sx={{
                                                    fontSize: 24,
                                                }}
                                            />
                                            <Box display="flex" alignItems="center">
                                                <Box ml={1.5}>
                                                    {/* <MediumText sx={{ textDecoration: 'line-through' }}>
                                                        {menuItem.discountedPrice[feeType]}
                                                    </MediumText> */}
                                                    <MediumText fontWeight="bold">
                                                        {paperFinalPrice} {t('mkd')}
                                                    </MediumText>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </PrimaryButton>
                                </Box>
                            )}
                        </Box>
                    </Box>
                </Box>

                {/*  */}
            </>
            {/* )} */}
            {(loading || addingItems) && (
                <Box
                    position="absolute"
                    height="100%"
                    width="100%"
                    style={{ backgroundColor: 'rgba(0,0,0,0.2)' }}
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-end"
                    zIndex={99}
                >
                    <LinearProgress color="secondary" style={{ width: '100%', height: '8px' }} />
                </Box>
            )}
            <ModalPopup open={openAlertPopup} handleClose={handleCloseAlertPopup} maxWidth="90vw">
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        paddingLeft: '10%',
                        paddingRight: '10%',
                        justifyContent: 'center',
                    }}
                >
                    <Box sx={{ paddingBottom: '20px' }}>
                        {hasOptionForSameType && <LargeText fontWeight="600">{t('changeDeliveryType')}</LargeText>}
                    </Box>
                    <Box sx={{ paddingBottom: '20px', textAlign: 'center' }}>
                        <MediumText>
                            {hasOptionForSameType
                                ? cart.data.type === 'delivery'
                                    ? t('toSelfPickup')
                                    : t('toDelivery')
                                : t('canNotAdd')}
                        </MediumText>
                    </Box>
                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <Button onClick={handleCloseAlertPopup}>
                            <MediumText sx={{ fontWeight: '700', color: 'red' }}>{t('cancel')}</MediumText>
                        </Button>
                        {hasOptionForSameType && (
                            <PrimaryButton sx={{ width: '50%', marginLeft: '30px' }} onClick={handleChangeCartDeliveryType}>
                                <NormalText sx={{ fontWeight: '600', textTransform: 'uppercase' }}>
                                    {t('change')}
                                    <br />
                                    {t('deliveryType')}
                                </NormalText>
                            </PrimaryButton>
                        )}
                    </Box>
                </Box>
            </ModalPopup>
        </Box>
    );
};

interface IIngredientsViewProps {
    ingredients: Ingredient[];
    extras: Extra[];
    quantity: number;
    menuItem: FoodItem;
    cartType: OrderType;
    feeType: FeeType;
    updateTabs?: (tabs: Tab[]) => void;
    onNewTab?: () => void;
    onCloseTab?: () => void;
}

export interface Tab {
    ingredients: Ingredient[];
    extras: Extra[];
    selectedPortion: string;
    comment: string;
}

export const IngredientsView = ({
    ingredients: ings,
    extras: exs,
    quantity,
    updateTabs,
    menuItem,
    onNewTab,
    onCloseTab,
    cartType,
    feeType,
}: IIngredientsViewProps) => {
    const [tabs, setTabs] = useState<Tab[]>([
        {
            ingredients: [..._.cloneDeep(ings)],
            extras: [..._.cloneDeep(exs)],
            selectedPortion: isEmpty(menuItem.portions)
                ? null
                : findKey(
                      menuItem.portions,
                      v => v.finalPrice?.[cartType] == min(map(menuItem.portions, p => p.finalPrice?.[cartType]))
                  ),
            comment: '',
        },
    ]);
    // const [comment, setComment] = useState<string>('');

    const [currentTab, setCurrentTab] = useState<number>(1);
    const { translate } = useDataTranslator();
    const { t } = useTranslation('common');
    const theme = useTheme();

    const indexToClose = useRef<number>(0);

    useEffect(() => {
        if (updateTabs) updateTabs(tabs);
    }, [tabs]);

    useEffect(() => {
        if (quantity === tabs.length + 1) {
            setTabs([
                ...tabs,
                {
                    ingredients: [..._.cloneDeep(ings)],
                    extras: [..._.cloneDeep(exs)],
                    selectedPortion: isEmpty(menuItem.portions)
                        ? null
                        : findKey(
                              menuItem.portions,
                              v => v.finalPrice?.[cartType] == min(map(menuItem.portions, p => p.finalPrice?.[cartType]))
                          ),
                    comment: '',
                },
            ]);
            if (tabs.length === currentTab) setCurrentTab(currentTab + 1);
        } else if (quantity === tabs.length - 1) {
            if (currentTab === indexToClose.current + 1) {
                if (indexToClose.current === tabs.length - 1) setCurrentTab(currentTab - 1);
                // else setCurrentTab(currentTab + 1);
            }
            if (indexToClose.current + 1 < currentTab) {
                setCurrentTab(currentTab - 1);
            }
            tabs.splice(indexToClose.current, 1);
            setTabs([...tabs]);
        }
    }, [quantity]);

    const handleClick = (name, index) => {
        const tab = tabs.find((t, ind) => ind === index);
        if (tab) {
            const ingredient = tab.ingredients.find(i => translate(i.ingredient.name) === name);
            if (ingredient) {
                ingredient.selected = !ingredient.selected;
                setTabs([...tabs]);
            }
        }
    };

    const handleClickExtra = (id, index) => {
        const tab = tabs.find((t, ind) => ind === index);
        if (tab) {
            const extra = tab.extras.find(e => e.item.id === id);
            if (extra) {
                extra.selected = !extra.selected;
                if (!extra.selected) {
                    extra.quantity = 0;
                } else {
                    extra.quantity = 1;
                }
                setTabs([...tabs]);
            }
        }
    };

    const handleQuantityIncreaseExtra = (id, index) => {
        const tab = tabs.find((t, ind) => ind === index);
        if (tab) {
            const extra = tab.extras.find(e => e.item.id === id);
            if (extra) {
                extra.quantity++;
                setTabs([...tabs]);
            }
        }
    };

    const handleQuantityDecreaseExtra = (id, index) => {
        const tab = tabs.find((t, ind) => ind === index);
        if (tab) {
            const extra = tab.extras.find(e => e.item.id === id);
            if (extra) {
                if (extra.quantity > 1) {
                    extra.quantity--;
                } else {
                    extra.quantity = 0;
                    extra.selected = false;
                }
                setTabs([...tabs]);
            }
        }
    };

    const handlePortionSelect = (portionId, index) => {
        tabs[index].selectedPortion = portionId;
        setTabs([...tabs]);
    };

    const changeTab = tab => {
        setCurrentTab(tab);
    };

    const handleCommentChange = (e, index) => {
        tabs[index].comment = e.target.value;
        // setComment(e.target.value);
        setTabs([...tabs]);
    };

    if (
        ((!menuItem.ingredients && !exs.length) || (isEmpty(menuItem.ingredients) && isEmpty(exs))) &&
        (!menuItem.portions || isEmpty(menuItem.portions))
    )
        return null;

    return (
        <Box width="100%" minHeight={100} mt={2}>
            <Box display="flex" width={'100%'} maxWidth={600}>
                <Box display="flex" overflow="hidden">
                    {tabs.map((t, index) => (
                        <Box
                            onClick={() => changeTab(index + 1)}
                            key={index}
                            whiteSpace="nowrap"
                            display="flex"
                            alignItems="center"
                            flex={1}
                            maxWidth={200}
                            minWidth={30}
                            overflow="hidden"
                            position="relative"
                            sx={{
                                '&::after': {
                                    content: "' '",
                                    backgroundColor: 'white',
                                    width: quantity > 1 ? 0 : 10,
                                    height: '100%',
                                    position: 'absolute',
                                    right: 0,
                                },
                                padding: `5px ${quantity > 1 ? '0px' : '5px'} 5px 5px`,
                                border: currentTab === index + 1 ? '2px solid #2CA8B2' : '1px solid #EDEDED',
                                borderStyle: 'solid',
                                borderWidth: currentTab === index + 1 ? '2px' : '1px',
                                borderColor: currentTab === index + 1 ? 'primary.main' : '#EDEDED',
                                borderRadius: '10px 10px 0 0',
                                cursor: 'pointer',
                            }}
                        >
                            <Box display="flex" width="100%" position="relative">
                                <Box position="absolute" left={0} sx={{ bgcolor: 'white' }} height="100%" px={0.5}>
                                    <SmallText color="primary">
                                        <b>{index + 1}</b>
                                    </SmallText>
                                </Box>
                                <Box display="flex" flex={1} alignItems="center" pl={2.2}>
                                    <Box overflow="hidden" mr={1} flex={1}>
                                        <SmallText>{translate(menuItem.name)}</SmallText>
                                    </Box>{' '}
                                </Box>

                                {quantity > 1 && (
                                    <Box zIndex={9} position="absolute" right={0} sx={{ bgcolor: 'white' }} pr={0.5}>
                                        <IconButton
                                            size="small"
                                            style={{ marginLeft: 5 }}
                                            onClick={event => {
                                                event.stopPropagation();
                                                indexToClose.current = index;
                                                if (onCloseTab) onCloseTab();
                                            }}
                                        >
                                            <Clear color="primary" />
                                        </IconButton>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    ))}
                </Box>
                <IconButton size="medium" style={{ padding: 8 }} onClick={onNewTab}>
                    <Add color="primary" />
                </IconButton>
            </Box>
            <Divider />
            {tabs.map((tab, index) => (
                <Box
                    style={{ display: currentTab === index + 1 ? 'block' : 'none' }}
                    key={index}
                    maxWidth={{ xs: 'unset', md: 600 }}
                >
                    <Render when={!isEmpty(menuItem.portions)}>
                        <Box mt={2} display="flex" alignItems="flex-start" flexDirection="column">
                            {/* <Box display="flex" alignItems="center" height={34}>
                                <img src={portionsIcon} style={{ width: 25 }} />
                                <Box ml={1}>
                                    <NormalText semibold>{t('portion', { count: 1 })}</NormalText>
                                </Box>
                            </Box> */}

                            <Box display="flex" alignItems="center" flexWrap="wrap">
                                {map(menuItem.portions, (portion, id: string) => (
                                    <Box key={id} mr={'2px'} mb={1}>
                                        <Tooltip
                                            title={
                                                <MediumText semibold>{`${portion.finalPrice?.[feeType]} ${t('mkd')}`}</MediumText>
                                            }
                                            arrow
                                            placement="top"
                                        >
                                            <div>
                                                <ToggleButton
                                                    selected={tab.selectedPortion === id}
                                                    onClick={() => handlePortionSelect(id, index)}
                                                    value={translate(portion.name)}
                                                >
                                                    <b>{translate(portion.name)}</b>
                                                </ToggleButton>
                                            </div>
                                        </Tooltip>
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                    </Render>
                    <Render when={!isEmpty(menuItem.ingredients)}>
                        {/* <Box display="flex" alignItems="center" height={34}>
                            <img src={ingredientsIcon} style={{ width: 25 }} />
                            <Box ml={1}>
                                <NormalText semibold>{t('ingredient', { count: 10 })}</NormalText>
                            </Box>
                        </Box> */}
                        <Box mt={1.5} display="flex" flexWrap="wrap">
                            {tab.ingredients.map(ing => (
                                <Box mb={1} mr={'3px'} key={translate(ing.ingredient.name)}>
                                    <ToggleButton
                                        sx={{
                                            width: '100%',
                                            textDecoration: ing.selected ? 'none' : 'line-through',
                                            ':hover': {
                                                textDecoration: ing.selected ? 'none' : 'line-through',
                                            },
                                        }}
                                        selected={ing.selected}
                                        onClick={() => handleClick(translate(ing.ingredient.name), index)}
                                        value={translate(ing.ingredient.name)}
                                    >
                                        <b>{translate(ing.ingredient.name)}</b>
                                    </ToggleButton>
                                </Box>
                            ))}
                        </Box>
                    </Render>
                    <Render when={!isEmpty(exs)}>
                        {/* <Box display="flex" alignItems="center" height={34}>
                            <img src={ingredientsIcon} style={{ width: 25 }} />
                            <Box ml={1}>
                                <NormalText semibold>{t('extra', { count: 10 })}</NormalText>
                            </Box>
                        </Box> */}
                        <Box mt={1.5} display="flex" flexWrap="wrap">
                            {tab?.extras?.map(ex => (
                                <Box
                                    mb={1}
                                    mr={'3px'}
                                    key={translate(ex.item.name)}
                                    sx={{
                                        display: 'flex',
                                    }}
                                >
                                    <ToggleButton
                                        sx={{ minWidth: '150px' }}
                                        selected={ex.selected}
                                        onClick={() => handleClickExtra(ex.item.id, index)}
                                        value={ex.item.id}
                                    >
                                        <b>
                                            {translate(ex.item.name)} - {ex.item.finalPrice?.[feeType]} {t('mkd')}
                                        </b>
                                    </ToggleButton>
                                    <Box
                                        sx={{
                                            width: '27px',
                                            height: '40px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'space-around',
                                            alignItems: 'center',
                                            marginLeft: 0.2,
                                        }}
                                    >
                                        {ex.selected && (
                                            <>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        fontSize: '17px',
                                                        fontWeight: 'bold',
                                                        color: theme.palette.primary.main,
                                                        height: '17px',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() => handleQuantityIncreaseExtra(ex.item.id, index)}
                                                >
                                                    +
                                                </Box>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        fontSize: '14px',
                                                        fontWeight: 'bold',
                                                        color: 'black',
                                                        height: '14px',
                                                        transform: 'translateY(2px)',
                                                    }}
                                                >
                                                    {ex.quantity}
                                                </Box>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        fontSize: '17px',
                                                        fontWeight: 'bold',
                                                        color: theme.palette.secondary.main,
                                                        height: '17px',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() => handleQuantityDecreaseExtra(ex.item.id, index)}
                                                >
                                                    -
                                                </Box>
                                            </>
                                        )}
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                    </Render>
                    <Box width="100%" py={1} display="flex" flexDirection="column">
                        <TextField
                            sx={{ '& .MuiOutlinedInput-input': { height: '10px', fontSize: '0.875rem' }, marginTop: '5px' }}
                            value={tabs[index].comment}
                            placeholder={t('addComment')}
                            onChange={e => handleCommentChange(e, index)}
                        />
                    </Box>
                    {/* <Expandable title="Extras" initialOpen={true}>
                        <Box display="flex" flexWrap="wrap" mt={1}>
                            {tab.extras.map(ing => (
                                <Box mr={2} mb={2} display="flex" position="relative">
                                    <ToggleButton
                                        selected={ing.quantity > 0}
                                        onClick={() => handleClickExtra(ing.extra, index)}
                                    >
                                        {ing.extra}
                                    </ToggleButton>
                                    <Box mt={2} mr={3} position="absolute" style={{ right: '0px' }}>
                                        <ItemQuantityComponent
                                            onChange={value =>
                                                changeExtraQuantity(ing.extra, index, value)
                                            }
                                            zeroBased
                                            vertical
                                        />
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                    </Expandable> */}
                </Box>
            ))}
        </Box>
    );
};

interface Ingredient {
    ingredient: { name: LocaleStrings };
    selected: boolean;
}

interface Extra {
    item: FoodItem;
    selected: boolean;
    quantity: number;
}

export default DetailsMenuItemPaper;
