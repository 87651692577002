import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Tooltip } from '@mui/material';
import { useTheme } from '@mui/system';
import { BaseButton, ButtonsCompound } from 'components/atoms/Buttons';
import { MediumText, NormalText, SmallText } from 'components/atoms/Typography';
import { BasicSelect } from 'components/molecules/BasicSelect';
import moment, { Moment } from 'moment';
import useTranslation from 'next-translate/useTranslation';
import { useEffect } from 'react';

interface IPreorderPickerProps {
    days?: Moment[];
    onDateSelect?: (value: string) => void;
    dateSelected?: Moment;
    times?: Moment[];
    onTimeSelect: (value: string, date?: any) => void;
    timeSelected?: Moment;
}

const PreorderPicker = ({ days, times, onDateSelect, onTimeSelect, dateSelected, timeSelected }: IPreorderPickerProps) => {
    const { t } = useTranslation('common');

    const getDay = (day: Moment) => {
        if (day) {
            if (day.day() === moment().day()) {
                return t('today');
            } else if (day.day() === moment().add(1, 'days').day()) {
                return t('tomorrow');
            } else return t(`days.${day.day()}`);
        }
    };

    console.log('LN', '>timeSelected', timeSelected);

    return (
        <Box>
            <Box display="flex" mt={1}>
                <Box flex={1}>
                    <Select
                        value={dateSelected ? dateSelected?.format('DD-MM-YYYY') : days?.[0] ? days[0].format('DD-MM-YYYY') : ''}
                        placeholder={t('time')}
                        onChange={event => onDateSelect(event.target.value)}
                        size="small"
                        MenuProps={{
                            sx: {
                                // width: 350,
                            },
                        }}
                        sx={{
                            color: 'primary.main',
                            width: { xs: '120px', md: '150px' },
                            p: 0.2,
                            fontWeight: 500,
                            borderRadius: '5px',
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#D8D8D8',
                            },
                        }}
                    >
                        {days.slice(0, 6).map((d, i) => (
                            <MenuItem key={i} value={d.format('DD-MM-YYYY')}>
                                {getDay(d)}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>

                {dateSelected ? (
                    <Box pl={1} fontSize="1.1em">
                        <Select
                            value={timeSelected ? timeSelected.format('HH:mm') : times?.[0] ? times[0].format('HH:mm') : ''}
                            placeholder={t('time')}
                            onChange={event => {
                                onTimeSelect(event.target.value);
                            }}
                            size="small"
                            MenuProps={{
                                sx: {
                                    maxWidth: 250,
                                    maxHeight: 400,
                                },
                            }}
                            sx={{
                                color: 'primary.main',
                                width: { xs: '85px', md: '90px' },
                                fontWeight: 500,
                                borderRadius: '5px',
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#D8D8D8',
                                },
                            }}
                        >
                            {/* <MenuItem value={null}>{t('time')}</MenuItem> */}
                            {times?.map(time => (
                                <MenuItem key={time.format('HH:mm')} value={time.format('HH:mm')}>
                                    {time.format('HH:mm')}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                ) : (
                    <Box
                        display="flex"
                        borderRadius="5px"
                        justifyContent="center"
                        alignItems="center"
                        width={{ xs: '70px', md: '80px' }}
                        height={{ xs: '37px', md: '42px' }}
                        ml={2}
                        border="1px solid #D8D8D8"
                    >
                        <MediumText color="#D8D8D8">--:--</MediumText>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default PreorderPicker;
