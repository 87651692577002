import { FoodItem } from 'klikni-jadi-shared-stuff';
import moment from 'moment';

export const checkServingTime = (foodItem: FoodItem) => {
    const fromString = foodItem.servingTime?.fromString;
    const toString = foodItem.servingTime?.toString;

    if (!fromString && !toString) {
        return true;
    }

    if (fromString && !toString) {
        return moment().isAfter(moment(fromString, 'HH:mm'));
    }

    if (!fromString && toString) {
        return moment().isBefore(moment(toString, 'HH:mm'));
    }

    return moment().isAfter(moment(fromString, 'HH:mm')) && moment().isBefore(moment(toString, 'HH:mm'));
};
