/* eslint prefer-const: 0 */

import { isClientSide } from 'services/utils';
import { checkUrl, createPixelArray } from './utils';
import ColorThief from 'colorthief';
import quantize from 'quantize';

export const getBackgroundColorFromImage = (image: string) =>
    new Promise<string>((resolve, reject) => {
        const canvas = document.createElement('canvas');
        checkUrl(image)
            .then(() => {
                const pic = new Image();
                pic.onload = function () {
                    canvas.width = pic.width;
                    canvas.height = pic.height;
                    const ctx = canvas.getContext('2d');

                    ctx.drawImage(pic, 0, 0);

                    const c = canvas.getContext('2d');
                    const [r, g, b, a] = c.getImageData(7, 7, 1, 1).data;
                    let rgb = `rgb(${[r, g, b].join(',')})`;
                    if (a === 0) rgb = 'rgb(255, 255, 255)';

                    resolve(rgb);
                };
                pic.crossOrigin = 'Anonymous';
                pic.src = image;
                pic.onerror = reject;
            })
            .catch(() => {
                reject('Not valid url.');
            });
    });

export const getTopBottomColorsFromImage = (image: string) =>
    new Promise<{ top: string; bottom: string }>((resolve, reject) => {
        const canvas = document.createElement('canvas');
        checkUrl(image)
            .then(() => {
                const pic = new Image();
                pic.onload = function () {
                    canvas.width = pic.width;
                    canvas.height = pic.height;
                    const ctx = canvas.getContext('2d');

                    ctx.drawImage(pic, 0, 0);

                    const imgData = ctx.getImageData(0, 0, canvas.width, canvas.height);
                    const data = imgData.data;
                    if (data[3] === 0) {
                        resolve({ top: 'rgb(255, 255, 255)', bottom: 'rgb(255, 255, 255)' });
                        return;
                    }

                    const topRow = data.slice(0, canvas.width * 4);
                    const bottomRow = data.slice(data.length - canvas.width * 4, data.length);

                    const topPixelArray = createPixelArray(topRow, canvas.width, 10);
                    const bottomPixelArray = createPixelArray(bottomRow, canvas.width, 10);

                    const cmapTop = quantize(topPixelArray, 5);
                    const cmapBottom = quantize(bottomPixelArray, 5);

                    const paletteTop = cmapTop ? cmapTop.palette() : null;
                    const paletteBottom = cmapBottom ? cmapBottom.palette() : null;

                    const top = paletteTop ? paletteTop[0] : [];
                    const bottom = paletteBottom ? paletteBottom[0] : [];

                    resolve({ top: `rgb(${top.join(',')})`, bottom: `rgb(${bottom.join(',')})` });
                };
                pic.crossOrigin = 'Anonymous';
                pic.src = image;
                pic.onerror = reject;
            })
            .catch(() => {
                reject('Not valid url.');
            });
    });

export const getAverageRGB = (image: string) =>
    new Promise<string>((resolve, reject) => {
        const canvas = document.createElement('canvas');
        checkUrl(image)
            .then(() => {
                const pic = new Image();
                pic.onload = function () {
                    canvas.width = pic.width;
                    canvas.height = pic.height;
                    const ctx = canvas.getContext('2d');

                    ctx.drawImage(pic, 0, 0);

                    const c = canvas.getContext('2d');
                    const data = c.getImageData(7, 7, 1, 1);
                    const pixels = data.data;

                    const rgb = { r: 255, g: 255, b: 255 };
                    const blockSize = 5;
                    let count = 0;
                    let i = -4;

                    length = data.data.length;

                    while ((i += blockSize * 4) < length) {
                        ++count;
                        rgb.r += data.data[i];
                        rgb.g += data.data[i + 1];
                        rgb.b += data.data[i + 2];
                    }

                    // ~~ used to floor values
                    rgb.r = ~~(rgb.r / count);
                    rgb.g = ~~(rgb.g / count);
                    rgb.b = ~~(rgb.b / count);

                    let rgbString = `rgb(${[rgb.r, rgb.g, rgb.b].join(',')})`;
                    if (pixels[3] === 0) rgbString = 'rgb(255, 255, 255)';

                    resolve(rgbString);
                };
                pic.crossOrigin = 'Anonymous';
                pic.src = image;
                pic.onerror = reject;
            })
            .catch(() => {
                reject('Not valid url.');
            });
    });

export const getDominantColorFromImage = (image: string) =>
    new Promise<{ color: string; palette: string[] }>((resolve, reject) => {
        checkUrl(image)
            .then(() => {
                if (isClientSide()) {
                    let img = document.createElement('img');
                    img.setAttribute('src', image);
                    img.crossOrigin = 'Anonymous';
                    img.addEventListener('load', () => {
                        const colorThief = new ColorThief();
                        const color = colorThief.getColor(img);
                        const palette = colorThief.getPalette(img);
                        resolve({
                            color: `rgba(${color.join(',')})`,
                            palette: palette.map(p => `rgba(${p.join(',')})`),
                        });
                    });
                } else {
                    reject();
                }
            })
            .catch(() => {
                reject('Not valid url.');
            });
    });
