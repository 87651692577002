import { Box, Theme } from '@mui/material';
import { useEffect, useState } from 'react';
import { ISizedTextProps, MediumText } from 'components/atoms/Typography';
import { styled } from '@mui/system';
import SellIcon from '@mui/icons-material/Sell';

type Filter = {
    title: string;
    id: string;
    hidden?: boolean;
    isOnPromotion?: boolean;
};

interface IFilterNavigationSidebarProps {
    data: Filter[];
}

interface StyledProps extends ISizedTextProps {
    theme?: Theme;
    active?: boolean;
}

const TransitionText = styled(MediumText)(({ theme, active }: StyledProps) => ({
    transition: theme.transitions.create(['font-size'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.leavingScreen,
    }),
    '&:hover': {
        color: `gray`,
    },
    '&::first-letter': {
        textTransform: 'capitalize',
    },
    color: active ? 'black' : 'grey',
    textDecoration: active ? 'underline' : 'none',
}));

const TransitionTextPromotions = styled(MediumText)(({ theme, active }: StyledProps) => ({
    transition: theme.transitions.create(['font-size'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.leavingScreen,
    }),
    '&:hover': {
        color: `#009de0`,
    },
    '&::first-letter': {
        textTransform: 'capitalize',
    },
    color: active ? '#009de0' : '#44B8FD',
    textDecoration: active ? 'underline' : 'none',
}));

const FilterNavigationSidebar = ({ data }: IFilterNavigationSidebarProps) => {
    const [visibleId, setVisibleId] = useState<string>(data.length ? data[0].id : '');

    useEffect(() => {
        const callback: IntersectionObserverCallback = entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setVisibleId(entry.target.id);
                }
            });
        };
        const options = {
            root: null,
            rootMargin: '-30% 0px -40% 0px',
            threshold: 0,
        };

        const observer = new IntersectionObserver(callback, options);

        data.filter(x => !x.hidden)
            .map(d => `#${d.id}`)
            .forEach(id => {
                const target = document.querySelector(id);
                if (target) observer.observe(target);
            });
        return () => {
            observer.disconnect();
        };
        // const setVisibleElement = id => {
        //     const el = document.getElementById(id);
        //     if (el) {
        //         if (elementInViewport(el)) {
        //             setVisibleId(id);
        //         }
        //     }
        // };
        // const handleScroll = () => {
        //     data.forEach(item => {
        //         setVisibleElement(item.id);
        //     });
        // };
        // document.addEventListener('scroll', _.throttle(handleScroll, 1000));
        // return () => document.removeEventListener('scroll', handleScroll);
    }, [data]);

    const handleScrollToId = id => {
        setVisibleId(id);
        const elem = document.getElementById(id);

        if (elem) {
            window.scrollTo({
                top: elem.offsetTop - 150,
                left: window.scrollX,
                behavior: 'smooth',
            });
            // elem.scrollIntoView();
            // if (id !== 'details-location' && id !== 'details-review') window.scrollBy(0, -250);
            // else window.scrollBy(0, -80);
        }
        // alert(elem.clientHeight);

        // window.scrollTo(window.scrollX, window.scrollY - elem.clientHeight);
    };

    return (
        <Box pr={2}>
            {data
                .filter(it => !it.hidden)
                .map(item => (
                    <Box
                        sx={{
                            cursor: 'pointer',
                        }}
                        key={item.id}
                        mb={2}
                        onClick={() => handleScrollToId(item.id)}
                    >
                        {item.id === 'category-promotions' || item.isOnPromotion ? (
                            <>
                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <SellIcon sx={{ color: '#44B8FD', marginRight: '0.5rem' }} />
                                    <TransitionTextPromotions active={visibleId === item.id}>
                                        {item.title}
                                    </TransitionTextPromotions>
                                </Box>
                                {/* <TransitionTextPromotions active={visibleId === item.id}>% {item.title}</TransitionTextPromotions> */}
                            </>
                        ) : (
                            <TransitionText active={visibleId === item.id}>{item.title}</TransitionText>
                        )}
                    </Box>
                ))}
        </Box>
    );
};

export default FilterNavigationSidebar;
