import { Box, SelectChangeEvent } from '@mui/material';
import { PrimaryButton } from 'components/atoms/Buttons';
import { LargeText, MediumText, NormalText } from 'components/atoms/Typography';
import ModalPopup from 'components/molecules/ModalPopup';
import PreorderPicker from 'containers/cart/components/PreorderPicker';
import { useAlertDialog } from 'hooks/useAlertDialog';
import useLocalStorage from 'hooks/useLocalStorage';
import moment, { Moment } from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';
import { useCartService } from 'services/cart/useCartService';
import { stateIsLoaded } from 'services/stateHelpers';
import useTranslation from 'next-translate/useTranslation';
import { forEach, isEmpty, isEqual, upperCase, values } from 'lodash';
import { Timestamp } from 'config/firebase';
import { useEffectInspector } from 'hooks/useEffectInspector';
import { _generateToken } from 'services/cart/useCartService/token';
import { useRest } from 'hooks/useRest';
import { PreorderData } from 'containers/cart/WhenCart';

interface IChooseDeliveryTimePopupProps {
    open: boolean;
    onClose?: () => void;
    onConfirm?: () => void;
    restaurantId: string;
    preorderData: PreorderData;
}

const ChooseDeliveryTimePopup = ({ open, onClose, onConfirm, restaurantId, preorderData }: IChooseDeliveryTimePopupProps) => {
    // const [preorderData?.days, setDays] = useState<Moment[]>([]);
    const [dateSelected, setDateSelected] = useState<Moment>(null);
    const [timeSelected, setTimeSelected] = useState<Moment>(null);
    // const [preorderData?.allResults, setAllResults] = useState<Record<string, Moment[]>>({});
    const cartService = useCartService();
    const datepickerRef = useRef(null);
    const cart = useSelector((state: RootState) => state.userCart);
    const currentUser = useSelector((state: RootState) => state.currentUser.data);
    const wannaAddItems = useRef<boolean>(false);
    const { alert } = useAlertDialog('ADD_ITEMS_ERROR');
    const [cartId, setCartId] = useLocalStorage('_cart_id');
    const { t } = useTranslation('common');
    const { propertiesAvailability } = useRest();
    const [isDefaultPreorderDateSet, setIsDefaultPreorderDateSet] = useState<boolean>(false);
    console.log('LN', 'cart', cart);

    useEffect(() => {
        if (datepickerRef.current) {
            if (datepickerRef.current.input) datepickerRef.current.input.readOnly = true;
        }
    }, []);
    // useEffect(() => {
    //     const restaurnatIds = new Set(cart?.data?.restaurantIds ? [restaurantId, ...cart.data.restaurantIds] : [restaurantId]); // If user goes back to same restaurant that is already added to the cart, the resId will be duplicate, hence the use of set.
    //     _generateToken().then(_token => {
    //         propertiesAvailability
    //             .getOne(Array.from(restaurnatIds).join(','), null, { 'x-access-token': _token }, 'availableHours')
    //             .then(res => {
    //                 const _dates = values(res?.data?.results).map(item => moment(item.date));
    //                 const _allResults = values(res?.data?.results).reduce(
    //                     (acc, item) => ({
    //                         ...acc,
    //                         [moment(item.date).format('DD-MM-YYYY')]: item.times.map(ts => moment(ts, 'HHmm')),
    //                     }),
    //                     {}
    //                 );
    //                 setDays(_dates);
    //                 setAllResults(_allResults);
    //             });
    //     });
    // }, [cart.data.restaurantIds]);

    useEffectInspector(
        changedDependencies => () => {
            if (!isEmpty(preorderData?.allResults) && !isEmpty(preorderData?.days)) {
                if (cart.data.deliveryDate) {
                    const userCartChanged = changedDependencies[2]
                        ? !isEqual(changedDependencies[2]?.after, changedDependencies[2]?.before)
                        : true;
                    if (userCartChanged) {
                        const deliveryMoment = moment(
                            new Timestamp(cart.data.deliveryDate.seconds, cart.data.deliveryDate.nanoseconds).toDate()
                        );
                        if (deliveryMoment.isValid()) {
                            const _dateSelected = deliveryMoment.clone();
                            const _timeSelected = deliveryMoment.clone();
                            if (
                                !preorderData?.days.map(d => d.format('DD-MM-YYYY')).includes(_dateSelected?.format('DD-MM-YYYY'))
                            ) {
                                const _newDate = preorderData?.days[0];

                                if (
                                    !preorderData?.allResults[_newDate.format('DD-MM-YYYY')]
                                        ?.map(t => t.format('HHmm'))
                                        .includes(_timeSelected?.format('HHmm')) &&
                                    preorderData?.allResults[_dateSelected.format('DD-MM-YYYY')]?.length
                                ) {
                                    const _newTime = preorderData?.allResults[_newDate.format('DD-MM-YYYY')]?.[0];

                                    setDateSelected(_newDate.clone());
                                    setTimeSelected(_newTime.clone());
                                } else {
                                    setDateSelected(_newDate.clone());
                                    setTimeSelected(_timeSelected.clone());
                                }
                            } else {
                                if (
                                    !preorderData?.allResults[_dateSelected.format('DD-MM-YYYY')]
                                        ?.map(t => t.format('HHmm'))
                                        .includes(_timeSelected?.format('HHmm')) &&
                                    preorderData?.allResults[_dateSelected.format('DD-MM-YYYY')].length
                                ) {
                                    const _newTime = preorderData?.allResults[_dateSelected.format('DD-MM-YYYY')]?.[0];

                                    setDateSelected(_dateSelected.clone());
                                    setTimeSelected(_newTime.clone());
                                } else {
                                    setDateSelected(deliveryMoment.clone());
                                    setTimeSelected(deliveryMoment.clone());
                                }
                            }
                        }
                    }
                }
            }
        },
        [preorderData?.allResults, preorderData?.days, cart.data.deliveryDate]
    );

    useEffect(() => {
        if (preorderData?.days[0] && !isEmpty(preorderData?.allResults) && !isDefaultPreorderDateSet) {
            handleDateSelect(preorderData?.days[0].format('DD-MM-YYYY'));
            setIsDefaultPreorderDateSet(true);
        }
    }, [preorderData?.days, preorderData?.allResults]);

    // const handleDateSelect = (value: string, isPreorder = true) => {
    //     const date = moment(value, 'DD MMMM YYYY');
    //     const firstPreorderTime = preorderData?.allResults[date.format('DD-MM-YYYY')][0];
    //     setDateSelected(date);
    //     handleTimeSelect(firstPreorderTime.format('HH:mm'), date, isPreorder);
    // };

    const handleDateSelect = (value: string) => {
        const date = moment(value, 'DD-MM-YYYY');
        const time = preorderData?.allResults[date.format('DD-MM-YYYY')][0].format('HH:mm');
        setDateSelected(date);
        // Set the first available time in the selected day.
        handleTimeSelect(time);
    };

    // const handleTimeSelect = (value: string, date = null, isPreorder = true) => {
    //     setTimeSelected(moment(value, 'HH:mm'));
    // };
    const handleTimeSelect = (value: string) => {
        const time = moment(value, 'HH:mm');
        setTimeSelected(time);
    };

    const handleConfirm = async () => {
        if (!cartId || cartId === 'undefined') {
            if (currentUser) {
                setCartId(currentUser.cartId);
            } else {
                const res = await cartService.createCart();
                cartService.setLoading();
                setCartId(res.data);
            }
            wannaAddItems.current = true;
        } else {
            wannaAddItems.current = false;

            const response = await cartService.addPreorder(
                moment(`${dateSelected.format('DD MMMM YYYY')} ${timeSelected.format('HH:mm')}`, 'DD MMMM YYYY HH:mm').toDate(),
                true
            );
            if (!response?.success) {
                alert(
                    {
                        title: response?.message,
                        description: response?.data.message,
                        successText: 'OK',
                        successColor: 'primary',
                        cancelColor: 'secondary',
                        cancelable: false,
                    },
                    () => {}
                );
            } else {
                if (onConfirm) onConfirm();
            }
        }
    };

    return (
        <ModalPopup open={open} handleClose={onClose}>
            <Box
                px={10}
                py={5}
                display="flex"
                flexDirection="column"
                alignItems="center"
                maxWidth={{ xs: '90vw', md: '70vw', lg: '50vw' }}
            >
                <Box>
                    <LargeText semibold>{t('chooseDeliveryTime')}</LargeText>
                </Box>
                <Box display="flex" mt={2}>
                    <MediumText sx={{ textAlign: 'center', maxWidth: 500 }}>{t('youCanOnlyPreorder')}</MediumText>
                </Box>
                <Box mt={2}>
                    {/* {!dateSelected && !timeSelected && ( */}
                    <PreorderPicker
                        days={preorderData?.days}
                        times={preorderData?.allResults[dateSelected?.format('DD-MM-YYYY')] || []}
                        timeSelected={timeSelected}
                        dateSelected={dateSelected}
                        onTimeSelect={handleTimeSelect}
                        onDateSelect={handleDateSelect}
                    />
                    {/* )} */}
                </Box>
                <Box mt={3}>
                    <PrimaryButton disabled={!dateSelected} onClick={handleConfirm}>
                        {upperCase(t('addDeliveryTime'))}
                    </PrimaryButton>
                </Box>
            </Box>
        </ModalPopup>
    );
};

export default ChooseDeliveryTimePopup;
